import React from 'react'
import styled from 'styled-components'
import three from '../../assets/images/3.jpg'

function WhyEM() {
    return (
        <Wrapper>
            <div className="left">
                <img src={three} alt="Episode Manager" />
            </div>
            <div className="right">
                <label>Make It Yours</label>
                <h1>Why <span>Episode Manager</span>?</h1>
                <p>Many new podcasters find keeping up with guest outreach, pre-calls, episode notes, commercials and sponsor content, calls to action for guests and more, a bit time consuming. Add to that all the steps after the recording to make a great episode and promote it out there and you can see why it can be difficult. That’s where EpisodeManager.com comes in. It was built by podcasters for podcasters to streamline all those tasks into a simple to use online platform to keep track of it all and leave the fun stuff to you.</p>
                <p>Let EpisodeManager.com do the heavy-lifting and free you up to do the really cool stuff, like interviewing awesome guests and passing on your expertise to the world.</p>
            </div>
        </Wrapper>
    )
}

export default WhyEM

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #1a2138;
    padding: 20px;
    height: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        padding: 20px 200px;
    }

    .left, .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .left {
        img {
            width: 100%;
            max-width: 400px; /* Adjust max-width as needed */
            border-radius: 25px;
        }
    }

    .right {
        label {
            color: #999;
        }

        h1 {
            span {
                color: #ff6a3c;
                font-weight: bold;
            }
        }

        p {
            font-size: 15px;
        }
    }
`
