import React from "react";
import PodcastTable from "../../../../components/podcast/PodcastTable";

const Podcast = () => {
  return (
    <div>
      <PodcastTable />
    </div>
  );
};

export default Podcast;