import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "../../axois/Axois";
import { getToken } from "../../utils/Auth";
import { ErrorNotification } from "../index";
import ShowMomentTable from "../ShowTable/ShowMomentTable";

const EpisodeMoments = ({ episodeData }) => {
  return (
    <Wrapper>
      {" "}
      <h3 className="heading">Episode Moments</h3>
      <p className="field">Moments Table</p>
      <ShowMomentTable data={episodeData?.moment_table} />
      <br />
      <p className="field">Moments Notes:</p>
      <p className="data-text">{episodeData?.moment_notes}</p>
    </Wrapper>
  );
};

export default EpisodeMoments;

const Wrapper = styled.div`
  padding: 10px 10px;
  // background: #e7dada;
  .heading {
    border-bottom: 1px solid rgba(53, 86, 129, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-family: "Ultra", sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    text-shadow: 0 2px white, 0 3px #777;
  }
  .field {
    // margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    font-family: "Orienta", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
  }

  .flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, 100px);
  }
  .data-text {
    all: initial;
    padding: 0px 10px;
    white-space: break-spaces;
  }
`;
