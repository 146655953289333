import React from "react";
import { Wrapper } from "./Dashboard.styled";
import UserTable from "../../../components/UserTable/UserTable";
import AnaylticCard from "../../../components/Analytics/Card";
import Chart from "../../../components/Analytics/Chart";
import { userData } from "../../../dummyData";

export default function IndexPage() {

  return (
    <Wrapper>

        <h2 className="heading"><span>Hi Steve, </span></h2>
      <AnaylticCard />

      <Chart
        data={userData}
        title="Sales Overview"
        grid
        dataKey="Sale"
      />
    </Wrapper>
  );
}
