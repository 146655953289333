import React, { useState } from "react";
import styledComponents from "styled-components";
import logo from "../../assets/images/EpisodeManager_Logo.png";
import logoWhite from "../../assets/images/EpisodeManager_White.png";
import { useHistory } from "react-router-dom";

function TopBar({ color, white }) {
  const history = useHistory();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // Function to close the mobile menu
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <Wrapper>
      <div className="topbar-container">
        <div className="logo">
          <a href="/home">
            {color && <img src={logo} alt="EpisodeManeger.jpg" />}
            {white && <img src={logoWhite} alt="EpisodeManeger.jpg" />}
          </a>
        </div>
        <div className="mobile-menu-button" onClick={toggleMobileMenu}>
          <div className={`hamburger ${mobileMenuOpen ? "open" : ""}`}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
        <div className={`menu-container ${mobileMenuOpen ? "open" : ""}`}>
          <div className="menu">
            <ul>
              <li>
                <a href="/home" onClick={closeMobileMenu}>Home</a>
              </li>
              <li>
                <a href="/aboutus" onClick={closeMobileMenu}>About us</a>
              </li>
              <li>
                <a href="/faq" onClick={closeMobileMenu}>FAQ</a>
              </li>
              <li>
                <a href="/pricing" onClick={closeMobileMenu}>Pricing</a>
              </li>
              <li>
                <a href="/contactus" onClick={closeMobileMenu}>Contact us</a>
              </li>
            </ul>
          </div>
          <div className="buttons">
            <div
              className="login"
              onClick={() => {
                history.push("/login");
                closeMobileMenu(); 
              }}
            >
              Login
            </div>
            <div
              className="signup"
              onClick={() => {
                history.push("/signup");
                closeMobileMenu(); 
              }}
            >
              Signup
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default TopBar;

const Wrapper = styledComponents.div`
  padding: 20px 30px;

  .topbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 150px;
    img {
      width: 100%;
    }
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    .menu-container {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 1;
      color:#1a2138;
      text-align: center;
    }

    .menu-container.open {
      display: flex;
    }

    .mobile-menu-button {
      display: block;
      cursor: pointer;
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .bar {
        width: 25px;
        height: 3px;
        background-color: #ff6a3c;
        margin: 3px 0;
        transition: 0.4s;
      }

      &.open {
        .bar {
          &:nth-child(1) {
            transform: rotate(-45deg) translate(-5px, 6px);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(45deg) translate(-5px, -6px);
          }
        }
      }
    }

    .menu {
      ul {
        margin: 20px 0;
      }

      li {
        list-style-type: none;
        margin: 10px 0;
      }

      a {
        color: #1a2138 !important;
        font-weight: bold;
      }

      /* Menu item hover effect */
      a:hover {
        color: #ff6a3c !important;
      }
    }
    .buttons{
      width: 85px;
      justify-content: center;
      margin-left: 225px;
      align-items: center;

    .login,
    .signup {
      background: #1a2138;
      padding: 6px 18px;
      border-radius: 5px;
      color: #fff;
      margin: 10px 0;
      cursor: pointer;
    }

    /* Login and Signup button hover effect */
    .login:hover,
    .signup:hover {
      background: #ff6a3c;
    }
  }
}
  /* Desktop styles */
  @media (min-width: 769px) {
    .mobile-menu-button {
      display: none;
    }

    .menu-container {
      display: flex !important;
      align-items: center;
    }

    .menu {
      ul {
        display: flex;
        align-items: center;
        margin: 0;
      }

      li {
        list-style-type: none;
        margin: 0 15px;
      }

      a {
        color: #1a2138;
        font-weight: bold;
      }

      a:hover {
        color: #ff6a3c;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      

      .login {
        background: #1a2138;
        padding: 6px 18px;
        border-radius: 5px;
        color: #fff;
        margin-right: 6px;
        cursor: pointer;
      }

      /* Login button hover effect */
      .login:hover {
        background: #ff6a3c;
      }

      .signup {
        outline: 2px solid #ff6a3c;
        padding: 4px 18px;
        border-radius: 5px;
        color: #ff6a3c;
        cursor: pointer;
      }

      /* Signup button hover effect */
      .signup:hover {
        background: #ff6a3c;
        color: #fff;
      }
    }
  }
`;
