import { Voicemail, People, Storage, AttachMoney } from "@material-ui/icons";

export const featuredData = [
  { title: "Podcasters", value: "200", moneyRate: "+0.0", icon: <Voicemail /> },
  { title: "Sub Members", value: "2,000", moneyRate: "+0.0", icon: <People /> },
  { title: "Episodes", value: "1,915", moneyRate: "+0.0", icon: <Storage /> },
  { title: "Sales", value: "$1,500", moneyRate: "+0.0", icon: <AttachMoney /> },
];

export const userData = [
  {
    name: "Jan",
    Sale: 300,
  },
  {
    name: "Feb",
    Sale: 220,
  },
  {
    name: "Mar",
    Sale: 800,
  },
  {
    name: "Apr",
    Sale: 400,
  },
  {
    name: "May",
    Sale: 700,
  },
  {
    name: "Jun",
    Sale: 200,
  },
  {
    name: "Jul",
    Sale: 600,
  },
  {
    name: "Aug",
    Sale: 800,
  },
  {
    name: "Sep",
    Sale: 450,
  },
  {
    name: "Oct",
    Sale: 275,
  },
  {
    name: "Nov",
    Sale: 325,
  },
  {
    name: "Dec",
    Sale: 600,
  },
];

export const newMemberRows = [
  {
    id: 1,
    username: "Mary Jane",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
    occupation: "Software Engineer",
  },
  {
    id: 2,
    username: "Lovely Singh",
    avatar: "https://randomuser.me/api/portraits/women/10.jpg",
    occupation: "Software Architect",
  },
  {
    id: 3,
    username: "Shikha Das",
    avatar: "https://randomuser.me/api/portraits/women/15.jpg",
    occupation: "Senior Designer",
  },
  {
    id: 4,
    username: "Reema Jain",
    avatar: "https://randomuser.me/api/portraits/women/34.jpg",
    occupation: "Senior Manager",
  },
  {
    id: 5,
    username: "Robin Hood",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    occupation: "Product Manager",
  },
];
