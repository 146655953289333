import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import UserAuthedLayout from "./layouts/userAuthed/UserAuthedLayout";
import AuthedLayout from "./layouts/authed/AuthedLayout";
import Podcaster from "./view/viewer/podcaster/Podcaster";
import Dashboard from "./view/viewer/dashboard/Dashboard";
import Login from "./view/auth/loginscreen/Login"; 
import Signup from "./view/auth/signupscreen/Signup";
import Home from "./view/viewer/home/Home";
import UserDashboard from "./view/viewer/userView/dashboard/Dashboard";
import UserEpisodes from "./view/viewer/userView/episodes/Episodes";
import UserTeamMembers from "./view/viewer/userView/teamMembers/TeamMembers";
import { getUser } from "./utils/Auth";
import Pricing from "./view/viewer/price/Pricing";
import NewEpisode from "./view/viewer/userView/newEpisode/NewEpisode";
import Accounts from "./view/viewer/userView/accounts/Accounts";
import AddUser from "./components/AddUser/AddUser";
import EditTeamMember from "./components/AddUser/EditTeamMember";
import EpisodeData from "./view/viewer/userView/episodes/episodeData/EpisodeData";
import EpisodeCreate from "./components/newEpisode/newEpisode/EpisodeCreate";
import FAQ from "./view/viewer/faq/FAQ";
import About from "./view/viewer/about/About";
import Contact from "./view/viewer/contact/Contact";
import UpdateName from "./components/episodeUpdate/UpdateName";
import Podcast from "./view/viewer/userView/podcast/Podcast";
import PodcastCreate from "./components/podcast/PodcastCreate";
import Season from "./components/podcastSeasons/Season";
import SeasonCreate from "./components/podcastSeasons/SeaonCreate";
import Guest from "./components/guest/Guest";




const Routes = () => {
  const [authLoading, setAuthLoading] = useState(true);
  const token = getUser();

  useEffect(() => {
    if (!token) {
      setAuthLoading(false);
    } else {
      setAuthLoading(false);
    }
  }, []);

  if (authLoading) {
    return <div>Checking Authentication...</div>;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          {token ? <Redirect to="/userdashboard" /> : <Login />}
        </Route>
        <Route path="/signup">
          {token ? <Redirect to="/userdashboard" /> : <Signup />}
        </Route>
        {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
        <PrivateRoute path="/podcaster" component={Podcaster} />
        <PrivateRoute path="/userdashboard" component={UserDashboard} />
        <PrivateRoute path="/episodeCreate" component={EpisodeCreate} />
        <PrivateRoute path="/episodes" component={UserEpisodes} />
        <PrivateRoute path="/newepisode" component={NewEpisode} />
        <PrivateRoute path="/episodeData" component={EpisodeData} />
        <PrivateRoute path="/updateEpisode" component={UpdateName} />
        <PrivateRoute path="/addNewTeamMember" component={AddUser} />
        <PrivateRoute path="/editTeamMember" component={EditTeamMember} />
        <PrivateRoute path="/teammembers" component={UserTeamMembers} />
        <PrivateRoute path="/podcast" component={Podcast} />
        <PrivateRoute path="/podcastCreate" component={PodcastCreate} />
        <PrivateRoute path="/season" component={Season} />
        <PrivateRoute path="/seasonCreate" component={SeasonCreate} />
        <PrivateRoute path="/guest" component={Guest} />
        <PrivateRoute path="/account" component={Accounts} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/home" component={Home} />
        <Route path="/" component={Home} exact={true} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/faq" component={FAQ} />
        <Route path="/aboutus" component={About} />
        <Route path="/contactus" component={Contact} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userRole = getUser()?.role;

  if (!getUser()) {
    return <Redirect to="/login" />;
  }

  if (userRole === "user") {
    return <Redirect to="/userdashboard" />;
  }

  return (
    <UserAuthedLayout>
      <Component {...rest} />
    </UserAuthedLayout>
  );
};
