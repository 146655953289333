import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import bgvideo from '../../assets/videos/backgroundvideo.mp4';

function GetStarted() {
  const history = useHistory();

  return (
    <Wrapper>
      <video autoPlay loop muted id="bg-video">
        <source src={bgvideo} type="video/mp4" />
      </video>
      <div className="main">
        <div className="main-content">
          <h1>Friendly Episode Manager</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </div>
        <div className="gs-btn" onClick={() => history.push('/signup')}>
          Get Started
        </div>
      </div>
    </Wrapper>
  );
}

export default GetStarted;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  
  #bg-video {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    backdrop-filter: brightness(0.75);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      /* Adjust styles for laptops and larger screens */
      padding: 0 250px;
    }

    .main-content {
      display: flex;
      color: white;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        color: white;
      }

      p {
        text-align: center;
        font-size: 15px;
      }
    }

    .gs-btn {
      outline: 2px solid #fff;
      padding: 4px 18px;
      border-radius: 5px;
      color: white;
      cursor: pointer;

      :hover {
        outline: 2px solid #ff6a3c;
        background: #ff6a3c;
        color: #fff;
      }
    }
  }
`;
