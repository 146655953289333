import React from 'react';
import {Wrapper} from './Contact.styled';
import TopBar from '../../../components/topBar/TopBar';
import ContactBody from '../../../components/Contact/ContactBody';

function Contact(){
    return(
        <Wrapper>
            <TopBar color />
            <ContactBody/>
        </Wrapper>
    )
}
export default Contact