import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';

import one from "../../assets/images/logo1.png";
import two from "../../assets/images/logo2.png";
import three from "../../assets/images/logo3.png";
import four from "../../assets/images/logo4.png";
import five from "../../assets/images/logo5.png";
import user1 from "../../assets/images/user1.png";
import user2 from "../../assets/images/user2.png";

function Feedbacks() {
    return (
        <Wrapper>
            <div className="top">
                <h1>
                    Join hundreds of thousands of companies and <br /> creatives hosting on Episode Manager
                </h1>
            </div>

            <div className="center">
                <img src={one} alt='img' />
                <img src={two} alt='img' />
                <img src={three} alt='img' />
                <img src={four} alt='img' />
                <img src={five} alt='img' />
            </div>

            <div className="bottom">
                <div className='card-body'>
                    <img src={user1} alt="user1" />
                    <div>"Melon is a great tool for creators of any kind to get their message and content out, gradually and surely grow their audience over time."</div>
                    <label>Low Dough Tech</label>
                </div>
                <div className='card-body'>
                    <img src={user2} alt="user2" />
                    <div>"Not everyone who needs to do a stream is a tech wizard with a super computer. Some people just want simplicity, and Melon has addressed this audience."</div>
                    <label>Feisworld Media</label>
                </div>
            </div>
            <br/>
            <br/>
        </Wrapper>
    );
}

export default Feedbacks;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #1a2138;
    background: #fff;
    padding: 20px; 


    .top {
        h1 {
            text-align: center;
            font-weight: bold;
        }
    }

    .center {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin-top: 20px; 
        margin-bottom: 50px; 
        
        img {
            height: 30px;
            margin: 10px;
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center; 
        width: 100%;

        @media (min-width: 768px) {
            flex-direction: row;
        }

        .card-body {
            position: relative;
            background: transparent;
            border: 2px solid #7c7c7c;
            border-radius: 15px;
            padding: 20px;
            margin: 10px; 
            width: 100%;
            max-width: 450px;
            text-align: center; 

            div {
                padding-top: 10px;
            }

            img {
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%); 
                width: 50px;
                height: 50px;
            }

            label {
                margin-top: 20px;
                color: #535e83;
            }
        }
    }
`;
