import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const ChartContainer = styled.div`
    margin: 30px 0px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    background: white;
    .recharts-wrapper{
        text{
            fill:#1a2138;
        }
        path{
            fill:#1a2138;
            stroke:#ff6a3c;
        }
    }
    `

const ChartTitle = styled.h3`
    margin-bottom: 20px;
    font-weight:bold;
    color:#1a2138;
`

const Chart = ({ title, data, dataKey, grid }) => {
    return (
        <ChartContainer>
            <ChartTitle>{title}</ChartTitle>
            <ResponsiveContainer width="100%" aspect={4 /1}>
                <LineChart data={data}>
                    <XAxis dataKey="name" stroke="#5550bd" />
                    <Line type="monotone" dataKey={dataKey} stroke="#483D8B" dot={false} />
                    <Tooltip />
                    {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
                </LineChart>
            </ResponsiveContainer>
        </ChartContainer>
    )
}

export default Chart
