import { Col, Row, Input, Form, Button, Spin } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getEpisodeId } from "../../../utils/Auth";
import { ErrorNotification, SuccessNotification } from "../../index";
import { getToken, getUser } from "../../../utils/Auth";
import axios from "../../../axois/Axois";
import Loading from "../../loading/Loading";
import { useLocation } from "react-router-dom";
import TextEditor from "../../textEditor/TextEditor";
import { serverAddress } from "../../../Constant";

const { TextArea } = Input;
const ShowInfo = ({ setTab }) => {
  const userRole = getUser()?.role;
  const id = getEpisodeId();
  const location = useLocation();
  const [formData, setFormData] = useState({
    episode_id: id,
    podcast: "",
    season_number: "",
    episode_number: "",
    episode_title: "",
    bio: "",
    something_interesting: "",
    special_links: [],
    intro_notes: "",
    sponsor_info: "",
    dry_read_commercial: "",
    author_book_info: "",
    author_book_link: "",
    book_notes: "",
  });
  const [richData, setRichData] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [isBtnloading, setIsBtnLoading] = useState(false);
  console.log("formData", formData);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(
        `${serverAddress}/episodeDetails/getEpisodeDetailsById?episode_id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setFormData({
          ...formData,
          podcast:
            res.data.podcast === undefined
              ? formData.podcast
              : res.data.podcast,
          season_number:
            res.data.season_number === undefined
              ? formData.season_number
              : res.data.season_number,
          episode_number:
            res.data.episode_number === undefined
              ? formData.episode_number
              : res.data.episode_number,
          episode_title:
            res.data.episode_title === undefined
              ? formData.episode_title
              : res.data.episode_title,
          bio: res.data.bio === undefined ? formData.bio : res.data.bio,
          something_interesting:
            res.data.something_interesting === undefined
              ? formData.something_interesting
              : res.data.something_interesting,
          special_links:
            res.data.special_links === undefined
              ? formData.special_links
              : res.data.special_links,
          intro_notes:
            res.data.intro_notes === undefined
              ? formData.intro_notes
              : res.data.intro_notes,
          sponsor_info:
            res.data.sponsor_info === undefined
              ? formData.sponsor_info
              : res.data.sponsor_info,
          dry_read_commercial:
            res.data.dry_read_commercial === undefined
              ? formData.dry_read_commercial
              : res.data.dry_read_commercial,
          author_book_info:
            res.data.author_book_info === undefined
              ? formData.author_book_info
              : res.data.author_book_info,
          author_book_link:
            res.data.author_book_link === undefined
              ? formData.author_book_link
              : res.data.author_book_link,
          book_notes:
            res.data.book_notes === undefined
              ? formData.book_notes
              : res.data.book_notes,
        });
        setRichData(
          res.data.intro_notes === undefined ? richData : res.data.intro_notes
        );
      })
      .catch((err) => {
        ErrorNotification("Request Failed");
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };
  const back = () => {
    setTab(1);
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleIntroNotesChange = (html) => {
    if (html != richData) {
      setRichData(html);
    }
  };

  const handleSpecialLinks = (index, event) => {
    const values = [...formData?.special_links];
    values[index][event.target.name] = event.target.value;
    setFormData({ ...formData, special_links: values });
  };

  const handleSubmit = () => {
    setIsBtnLoading(true);
    axios
      .post(
        `${serverAddress}/episodeDetails/editEpisodeDetails`,
        { ...formData, intro_notes: richData },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        console.log(res);
        setIsBtnLoading(false);
        SuccessNotification("Episode info saved successfully");
        setTab(3);
      })
      .catch((err) => {
        ErrorNotification("Error saving episode info");
        console.log(err);
        setIsBtnLoading(false);
      });
  };

  return (
    <Wrapper>
      {isloading ? (
        <Spin />
      ) : (
        <>
           <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                <Button onClick={back}>Prev Form</Button>
              </Col>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
                <Button
                  className="submit"
                  onClick={handleSubmit}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>

          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={8} xxl={8}>
              Podcast:{" "}
              <Input
                id="podcast"
                type="text"
                placeholder="Enter Podcast "
                defaultValue={formData?.podcast}
                value={formData?.podcast}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={8} xxl={8}>
              Season#:{" "}
              <Input
                id="season_number"
                type="text"
                placeholder="Enter Season No. "
                defaultValue={formData?.season_number}
                value={formData?.season_number}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={8} xxl={8}>
              Episode#:
              <Input
                id="episode_number"
                type="text"
                placeholder="Enter Episode No."
                defaultValue={formData?.episode_number}
                value={formData?.episode_number}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div style={{ whiteSpace: "pre" }}>Episode Title:</div>
              <Input
                id="episode_title"
                type="text"
                placeholder="Enter Episode Title "
                defaultValue={formData?.episode_title}
                value={formData?.episode_title}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              Bio:
              <TextArea
                rows={10}
                placeholder="Enter Bio "
                onChange={handleChange}
                id="bio"
                type="text"
                defaultValue={formData?.bio}
                value={formData?.bio}
              />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div style={{ whiteSpace: "pre" }}>Something Intresting:</div>
              <Input
                placeholder="Enter Something Intresting "
                onChange={handleChange}
                id="something_interesting"
                type="text"
                defaultValue={formData?.something_interesting}
                value={formData?.something_interesting}
              />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div className="links">
                <div style={{ whiteSpace: "pre" }}>Special Link:</div>
                {formData?.special_links.map((item, index) => (
                  <Input
                    key={index}
                    placeholder="www.example.com "
                    onChange={(event) => handleSpecialLinks(index, event)}
                    name="link"
                    type="text"
                    value={item.link}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div style={{ whiteSpace: "pre" }}>Intro Notes:</div>
              <TextEditor
                id="intro_notes"
                type="text"
                rows={8}
                placeholder="Enter Intro Notes "
                value={richData}
                onChange={handleIntroNotesChange}
              />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div style={{ whiteSpace: "pre" }}>Dry Read Commercial:</div>
              <TextArea
                id="dry_read_commercial"
                type="text"
                placeholder="Enter Dry Read Commercial "
                rows={10}
                defaultValue={formData?.dry_read_commercial}
                value={formData?.dry_read_commercial}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <Form.Item>
                <div style={{ whiteSpace: "pre" }}>Author Book Info:</div>
                <TextArea
                  rows={5}
                  placeholder="Enter Book Info "
                  id="author_book_info"
                  onChange={handleChange}
                  type="text"
                  value={formData?.author_book_info}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <Form.Item>
                <div style={{ whiteSpace: "pre" }}>Author Book Link:</div>
                <TextArea
                  rows={3}
                  placeholder="Enter Book Link "
                  id="author_book_link"
                  onChange={handleChange}
                  type="text"
                  value={formData?.author_book_link}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <Form.Item>
                <div style={{ whiteSpace: "pre" }}>Book Notes:</div>
                <TextArea
                  rows={15}
                  placeholder="Enter Book Notes "
                  id="book_notes"
                  onChange={handleChange}
                  type="text"
                  value={formData?.book_notes}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <br />
          <div className="tab-navigate">
            <Row>
            <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                <Button onClick={back}>Prev Form</Button>
              </Col>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
                <Button
                  className="submit"
                  onClick={handleSubmit}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Wrapper>
  );
  // );
};

export default ShowInfo;

const Wrapper = styled.div`
  .fields {
    align-items: center;
  }
  .ant-input {
    border-radius: 5px;
  }
  .ant-row {
    margin: 10px;
  }

  .links {
    .ant-input {
      margin-bottom: 10px;
    }
  }
  .submit {
    text-align: end;
  }
  .tab-navigate {
    .ant-btn {
      background: #1a2138;
      color: white;
    }
  }
`;
