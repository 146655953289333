import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px;
  padding: 10px;
`;

const StyledPanel = styled(Panel)`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FAQCollapse = () => {
  return (
    <StyledCollapse accordion ghost>
      <StyledPanel header="This is panel header 1" key="1">
        <p>FREQUENTLY ASKED QUESTIONS 1</p>
      </StyledPanel>
      <StyledPanel header="This is panel header 2" key="2">
        <p>FREQUENTLY ASKED QUESTIONS 2</p>
      </StyledPanel>
      <StyledPanel header="This is panel header 3" key="3">
        <p>FREQUENTLY ASKED QUESTIONS 3</p>
      </StyledPanel>
    </StyledCollapse>
  );
};

export default FAQCollapse;
