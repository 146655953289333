import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import BaseLayout from "../base/BaseLayout";
import TopHeader from "./topHeader/TopHeader";
import Sidebar from "./sidebar/SidebarPage";
import { useSelector } from "react-redux";

const { Header, Content } = Layout;

export default function UserAuthedLayout({ children, pathname }) {
  const isCollapse = useSelector((state) => state.common.isCollapse);
  console.log("pathname",pathname)
  return (
    <BaseLayout>
      <LayoutWrapper>
        <Layout>
          <Sidebar />

          <Layout
            className="ant-custom-layout"
            style={{
              marginLeft: isCollapse ? "230px" : "80px",
            }}
          >
            <Header className="site-layout-background">
              <TopHeader />
            </Header>

            <Content>
              <Wrapper className={"pageContent"}>
                <div className={"container"} style={{ height: "100%" }}>
                  {children}{" "}
                </div>
              </Wrapper>
            </Content>
          </Layout>
        </Layout>
      </LayoutWrapper>
    </BaseLayout>
  );
}

const Wrapper = styled.div`
  height: 100%;
  background-color: #f7f8fd;
  padding: 30px;
`;
const LayoutWrapper = styled.div`
  height: 100%;

  .ant-layout {
    height: 100%;
  }

  .ant-custom-layout {
    margin-left: 80px;
    transition: margin-left 0.7s;
    transition-timing-function: ease-in-out;
  }
  .site-layout-background {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
  }
  .ant-layout-header {
    padding: 0px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 200;
  }
  @media (max-width: 1000px) {
    .ant-custom-layout {
      margin: 0px !important;
    }
  }
`;
