import React, { useState } from "react";
import EpisodeMoments from "../../../../components/newEpisode/forms/EpisodeMoments";
import EpisodePromotion from "../../../../components/newEpisode/forms/EpisodePromotion";
import EpisodeTopic from "../../../../components/newEpisode/forms/EpisodeTopic";
import ShowInfo from "../../../../components/newEpisode/forms/ShowInfo";
import GuestInfo from "../../../../components/newEpisode/forms/GuestInfo";
import EpisodeAsset from "../../../../components/newEpisode/forms/EpisodeAsset";
import { Wrapper } from "./NewEpisode.styled";
import FormTabs from "../../../../components/newEpisode/formTabs/FormTabs";

const NewEpisode = () => {
  const [tab, setTab] = useState(1);
  const [userData, setUserData] = useState({});

  const renderTabContent = () => {
    switch (tab) {
      case 1:
        return <GuestInfo setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />;
      case 2:
        return <ShowInfo setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />;
      case 3:
        return <EpisodeTopic setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />;
      case 4:
        return <EpisodeAsset setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />;
      case 5:
        return <EpisodeMoments setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />;
      case 6:
        return <EpisodePromotion setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />;
      default:
        return null;
    }
  };

  return (
    <Wrapper id="new-episode">
      <FormTabs setTab={setTab} tab={tab} userData={userData} setUserData={setUserData} />
      <div className="tab-container">
        {renderTabContent()}
      </div>
    </Wrapper>
  );
};

export default NewEpisode;
