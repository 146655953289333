import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Table,
  Space,
  Card,
  Pagination,
  Button,
  Modal,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axois/Axois";
import { getToken, getUser } from "../../utils/Auth";
import { serverAddress } from "../../Constant";
import { useHistory } from "react-router-dom";
import {
  ErrorNotification,
  SuccessNotification,
} from "../notification/Notification";

const UserTable = () => {
  const podcaster = useSelector((state) => state.common.podcaster);
  const users = useSelector((state) => state.common.users);
  const dispatch = useDispatch();
  const SubUsers = useSelector((state) => state.common.SubUsers);
  const history = useHistory();
  const [loader, setLoader] = useState(false);  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [select, setSelect] = useState(""); 
  const [searchValue, setSearchValue] = useState([]);

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const GetUser = () => {
    setLoader(true);
    axios
      .get(`${serverAddress}/teamMember/getAllTeamMember`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        setLoader(false);  
        setSearchValue(res?.data?.data);
      })
      .catch((err) => {
        console.log("error message==>", err);
        setLoader(false);  
      });
  };

  const GetPodcaster = () => {
    axios
      .get("/podcaster/getAllPodcaster", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log("res", res);
        dispatch({
          type: "PODCASTER_USERS",
          payload: {
            podcaster: res?.data,
          },
        });
        setLoader(false);  
      })
      .catch((err) => {
        console.log("error message==>", err);
      });
  };

  const onDelete = (id, e) => {
    e.preventDefault();
    setLoader(true);  
  };

  useEffect(() => {
    GetUser();
    GetPodcaster();
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Podcaster Name",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={handleButtonClick}>View Team Members</Button>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (value, record) => {
        return (
          <>
            <Modal
              title="Team Members"
              visible={isModalVisible}
              onCancel={handleModalClose}
              width={1000}
              bodyStyle={{ height: '400px' }}
              footer={null}
            >
              <Card>
                <StyledTable
                  tableLayout={"unset"}
                  pagination={<Pagination defaultCurrent={5} total={users?.length} />}
                  columns={columns}
                  loading={loader}  // Changed loader to setLoader
                  scroll={{ x: 500 }}
                />
              </Card>
            </Modal>

            <Space size="middle">
              <Space>
                <Popconfirm
                  title="Are you sure to delete this Episode"
                  onConfirm={(e) => {
                    onDelete(record.id, e);
                  }}
                  okText="Delete"
                  okType="danger"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Space>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card title="Podcasters">
        <StyledTable
          tableLayout={"unset"}
          pagination={<Pagination defaultCurrent={5} total={users?.length} />}
          columns={columns}
          dataSource={podcaster}
          loading={loader}  // Changed loader to setLoader
          scroll={{ x: 500 }}
        />
      </Card>
    </>
  );
};

export default UserTable;

const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }

  .ant-table {
    overflow-x: auto;
    max-width: 100%;
  }
`;
