import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/abstract.jpg';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';

function PricingCard(props) {
  return (
    <Wrapper>
      <Card className='price-card'>
        <div className='bg-blur'>
          {props.main && (
            <h1 style={{ backgroundColor: '#ff6a3c' }}>
              Simple and Flexible Pricing
            </h1>
          )}
          {!props.main && <h1>Simple and Flexible Pricing</h1>}
          <p>Pricing is based on a Podcast.</p>
          <div className='divider' />

          <div className='rate'>
            <div style={{ marginTop: '13px' }}>$</div>
            <span className='price-lg'>{props.rate}</span>
            <p>&nbsp; / month per podcaster</p>
          </div>
          <div>
            <span className='price-sm'>$3</span> per member of your team as you
            grow
          </div>
          <div className='divider' />
          <div>
            <div className='unlimited'>
              <DoneRoundedIcon />
              <p>Unlimited episodes</p>
            </div>
            <div className='unlimited'>
              <DoneRoundedIcon />
              <p>Unlimited seasons</p>
            </div>
            <div className='unlimited'>
              <DoneRoundedIcon />
              <p>Unlimited history</p>
            </div>
          </div>
          {props.main && (
            <div className='btn' style={{ marginTop: '40px' }}>
              Start Trial
            </div>
          )}
          {!props.main && (
            <div className='btn' style={{ marginTop: '10px' }}>
              Start Trial
            </div>
          )}
        </div>
      </Card>
    </Wrapper>
  );
}

export default PricingCard;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 5px;

  .price-card {
    color: #1a2138;
    width: 100%; /* Use full width for small screens */
    text-align: center;
    border-radius: 35px;
    margin-bottom: 20px;

    .bg-blur {
      display: flex;
      flex-direction: column;
      align-items: center;
      backdrop-filter: blur(2px);
    }
    p {
      font-size: 15px;
      margin: 0px;
    }
    h1 {
      font-size: 20px;
      font-weight: bold;
      background: #1a2138;
      color: white;
      width: 100%;
      border-radius: 30px;
      padding: 10px;
    }
    .ant-card-body {
      background: #f3f3f3;
      border-radius: inherit;
      padding: 15px;
    }
    .rate {
      display: flex;
      align-items: start;
      .price-lg {
        font-weight: 800;
        font-size: 3rem;
      }
      p {
        align-self: end;
        margin-bottom: 18px;
        font-size: 10px;
      }
    }
    .price-sm {
      font-weight: 800;
    }
    .divider {
      height: 1px;
      margin: 20px 0px;
      width: 100%;
      backdrop-filter: revert;
      background: black;
    }
    .unlimited {
      display: flex;
    }
    .btn {
      margin-top: 10px;
      width: 100%;
      color: white;
      padding: 15px;
      border-radius: 30px;
      background-color: #1a2138;
      :hover {
        background-color: #ff6a3c;
        color: white;
      }
    }
  }

  /* Media query for small screens */
  @media (max-width: 768px) {
    .price-card {
      width: 90%; /* Reduce width for small screens */
      h1 {
        font-size: 18px; /* Adjust font size for smaller screens */
      }
      .btn {
        margin-top: 10px;
      }
    }
  }
`;
