import React, { useState } from "react";
import styled from "styled-components";
import { Card, Input, Button, message } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getToken, setEpisodeId } from "../../utils/Auth";
import { serverAddress } from "../../Constant";
import { ErrorNotification, SuccessNotification } from "../../components";

const PodcastCreate = () => {
  const [podcastData, setPodcastData] = useState({
    Podcast_name: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPodcastData({
      ...podcastData,
      [name]: value,
    });
  };

  const AddPodcast = async (event) => {
    event.preventDefault();
    const { Podcast_name } = podcastData;

    if (Podcast_name === "") {
      message.error("Please fill all fields");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${serverAddress}/podcast/add_Podcast`,
        { Podcast_name },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      setEpisodeId(response.data.Podcast.id);
      SuccessNotification("Podcast created successfully");

      history.push({ pathname: "/podcast", id: response.data.Podcast.id });
    } catch (err) {
      message.error("Invalid Information, please check again");
      ErrorNotification(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Wrapper>
      <Card>
        <form onSubmit={AddPodcast}>
          <div className="loginMain">
            <div className="bodyContent">
              <h2>Add New Podcast</h2>

              <div className="inputField">
              <label>Enter a Podcast Name:</label>
                <Input
                  name="Podcast_name"
                  placeholder="Podcast Name"
                  type="text"
                  onChange={handleChange}
                  value={podcastData.Podcast_name}
                />
              </div>

              <br />

              <div className="bottomSection">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!podcastData.Podcast_name}
                >
                  Add Podcast
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </Wrapper>
  );
};

export default PodcastCreate;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  color: white;

  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }

  .bodyContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #000;
      font-weight: 800;
      font-size: 25px;
    }
  }

  .bottomSection {
    width: 40%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      width: 200px;
      height: 45px;
      align-self: end;
      border: none;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      background: #1a2138;

      &:hover {
        background: #ff6a3c;
        border: 1px solid white;
      }
    }
  }

  .inputField {
    width: 40%;
    margin-top: 20px;

    .ant-input {
      width: 100%;
      padding: 10px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      color: black;
      font-weight: 500;
      transition: all 0.5s ease-out;

      &:not(.ant-input-affix-wrapper > input.ant-input):focus,
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused:focus {
        border: 1px solid black;
        box-shadow: 0 0 0 1px #1a2138 !important;
        transition: all 0.5s ease-out;
      }

      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border: 1px solid orange !important;
      }

      .ant-input-affix-wrapper {
        width: 100%;
        padding: 10px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        color: #c4c4c4;
        font-weight: 500;
      }
    }
  }
`;
