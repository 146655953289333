import React from "react";
import SubUserTable from "../../../../components/teamMembers/subUserTable/SubUserTable";

const TeamMembers = () => {
  return (
    <div>
      <SubUserTable />
    </div>
  );
};

export default TeamMembers;
