import React from "react";
import UserTable from "../../../components/UserTable/UserTable";

const TeamMembers = () => {
  return (
    <div>
      <UserTable />
    </div>
  );
};

export default TeamMembers;
