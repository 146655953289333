import styled from "styled-components";

export const Wrapper = styled.div`
  .heading {
    margin: 0px 0px 30px 0px;
    color: #afafaf;
    font-size: 24px;
    span {
      font-weight: bold;
      color: #ff6a3c;
    }
  }
  .image {
    width: 100px;
    height: 100px;
  }
`;
