import styled from "styled-components";

export const Wrapper = styled.div`
  .iQPlYt {
    top: 0;
    position: sticky;
    z-index: 10;
    
  }
  .navbar.colorChange {
    background: black;
  }
  .topbar-container {
    .menu-container {
      li {
        a {
          color: white;
        }
        a:hover {
          color: #ff6a3c;
        }
      }
      .buttons {
        .login,.signup{
            width: 85px;       
            text-align: center;
        }
        .login {
          outline: 2px solid #1a2138;
          padding: 4px 18px;
          margin-right: 10px;
          :hover {
            outline: 2px solid #ff6a3c;
          }
        }
        .signup {
          outline: 2px solid #fff;
          color: white;
          :hover {
            outline: 2px solid #ff6a3c;
          }
        }
      }
    }
  }
`;
