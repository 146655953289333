import React, { useState } from "react";
import { Table, Space, Input, Checkbox } from "antd";
import styled from "styled-components";

const InputTables = ({ formData, setFormData ,id }) => {
  console.log("iiiidddd",id)
  const [loader, setloader] = useState(false);
console.log(formData,"fffffff")
  const handleChange = (index, name, value) => {
    setFormData({
      ...formData,
      moment_table: formData?.moment_table?.map((el, ind) => {
        if (index == ind) {
          return {
            ...el,
            [name]: value,
          };
        } else {
          return el;
        }
      }),
    });
  };

  const columns = [
    {
      title: "Moment #",
      dataIndex: "momentNo",
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <Input />
      //     </Space>
      //   ),
    },
    {
      title: "Moment",
      dataIndex: "moment",
      render: (text, record) => (
        <Space size="middle">
          <Input
            value={text}
            onChange={(e) =>
              handleChange(record?.momentNo - 1, "moment", e.target.value)
            }
          />
        </Space>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "timeStamp",
      render: (text, record) => (
        <Space size="middle">
          <Input
            value={text}
            onChange={(e) =>
              handleChange(record?.momentNo - 1, "timeStamp", e.target.value)
            }
          />
        </Space>
      ),
    },
    {
      title: "Pull Quote",
      dataIndex: "quoteWorth",
      render: (text, record) => (
        <Space size="middle">
          <Checkbox
            checked={text}
            onChange={(e) =>
              handleChange(record?.momentNo - 1, "quoteWorth", e.target.checked)
            }
          />
        </Space>
      ),
    },
    {
      title: "Audiogram",
      dataIndex: "audiogramWorthy",
      render: (text, record) => (
        <Space size="middle">
          <Checkbox
            checked={text}
            onChange={(e) =>
              handleChange(
                record?.momentNo - 1,
                "audiogramWorthy",
                e.target.checked
              )
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <StyledTable
        pagination={false}
        // rowSelection={{
        //   ...rowSelection,
        // }}
        // expandable={expandable}
        // bordered
        tableLayout={"unset"}
        // title={title}
        // showHeader={showHeader}
        columns={columns}
        dataSource={formData?.moment_table}
        // scroll={{ y: 500 }}
        loading={loader}
      />
    </>
  );
};

export default InputTables;

const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }
  //   .ant-table-tbody > tr.ant-table-row:hover > td,
  //   .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  //     background: #ff6a3c;
  //   }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }
`;
