import React from 'react'
import styled from 'styled-components'
import one from '../../assets/images/1.jpg'

function WhatIsEM() {
    return (
        <Wrapper>
            <div className="left">
                <img src={one} alt="Episode Manager" />
            </div>
            <div className="right">
                <label>Make It Yours</label>
                <h1>What is <span>Episode Manager</span>?</h1>
                <p>Let EpisodeManager.com manage the people and the process of making a great podcast episode and leave the creativity to you. Once you launch a podcast, you need a system to help you plan, manage notes, guide recordings, interviews, maintain team workflow, help your editors as well as your promotional folks. Sometimes all these people are YOU, and that’s OK too.</p>
            </div>
        </Wrapper>
    )
}

export default WhatIsEM

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFF;
    color: #1a2138;
    padding: 20px;
    height: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        padding: 20px 200px;
    }

    .left, .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .left {
        img {
            width: 100%;
            max-width: 400px; /* Adjust max-width as needed */
            border-radius: 25px;
        }
    }

    .right {
        label {
            color: #999;
        }

        h1 {
            span {
                color: #F66B0E;
                font-weight: bold;
            }
        }

        p {
            font-size: 15px;
        }
    }
`
