import React, { useEffect } from "react";
import Routes from "./Routes";
import "antd/dist/antd.css";
import "./App.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { removeEpisodeId } from "./utils/Auth";

function App() {
  // const history = useHistory();
  // const location = history.push({ pathname: "/" });
  // console.log(location);

  return (
    <div style={{ height: "100%" }}>
      <Routes />
    </div>
  );
}

export default App;
