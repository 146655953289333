import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background: white;
  overflow-y: hidden;

  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }
  .bodyContent {
    display: flex;
    flex-direction: column;
    height: calc(100% - 180px);
    justify-content: center;

    .formContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    h2 {
      color: #1a2138;
      font-weight: bold;
      font-size: 18px;
      margin: 0px;
    }
  }
  .forgotPassword {
    margin: 10px 0px 10px 0px;
    align-self: end;
    color: #1a2138;
    font-weight: bold;
    cursor: pointer;
  }
  .bottomSection {
    width: 40%;
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      height: 45px;
      background: #1a2138;
      border-radius: 5px;
      font-weight: bold;
      border: none;
    }
    button:hover {
      background: #ff6a3c;
    }
  }
  .inputField {
    width: 40%;
    margin-top: 20px;
  }
  .ant-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-weight: 500;
  }
  .ant-btn[disabled] {
    color: white;
  }
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-weight: 500;
  }
  
`;
