import React, { useState, useRef, useEffect } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import styled from "styled-components";
import Loading from "../../../../../components/loading/Loading";
import { useLocation } from "react-router-dom";
import { Card, Button } from "antd";
import EpisodeAssets from "../../../../../components/userEpisodeData/EpisodeAssets";
import EpisodeInfo from "../../../../../components/userEpisodeData/EpisodeInfo";
import EpisodePromotion from "../../../../../components/userEpisodeData/EpisodePromotion";
import EpisodeMoments from "../../../../../components/userEpisodeData/EpisodeMoments";
import EpisodeTopics from "../../../../../components/userEpisodeData/EpisodeTopics";
import { getEpisodeId, getToken } from "../../../../../utils/Auth";
import axios from "axios";
import { serverAddress } from "../../../../../Constant";

const EpisodeData = () => {
  const [episodeData, setEpisodeData] = useState({});
  const location = useLocation();
  const id = getEpisodeId();
  const name = location?.name;
  const [isLoading, setIsLoading] = useState(true);
  const pdfDocumentRef = useRef(null);

  const downloadSectionAsPDF = async (sectionId, sectionName) => {
    setIsLoading(true);
    const section = pdfDocumentRef.current.querySelector(`#${sectionId}`);
    const canvas = await html2canvas(section, { scrollY: -window.scrollY });
    const imgData = canvas.toDataURL("image/png");

    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const doc = new jsPDF("p", "mm");
    let position = 0;
    doc.addImage(imgData, "PNG", 10, 2, imgWidth, imgHeight);
    let heightLeft = imgHeight - pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 10, 2 + position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    doc.save(`${sectionName}.pdf`);
    setIsLoading(false);
  };

  const handleDownloadAll = async () => {
    setIsLoading(true);
    const sections = [
      { id: "episode-info", name: "EpisodeInfo" },
      { id: "episode-topics", name: "EpisodeTopics" },
      { id: "episode-moments", name: "EpisodeMoments" },
      { id: "episode-promotion", name: "EpisodePromotion" },
      { id: "episode-assets", name: "EpisodeAssets" },
    ];

    const doc = new jsPDF("p", "mm");
    let position = 0;

    const sectionPromises = sections.map(async (section) => {
      const sectionId = section.id;
      const sectionElement = pdfDocumentRef.current.querySelector(
        `#${sectionId}`
      );
      const canvas = await html2canvas(sectionElement, {
        scrollY: -window.scrollY,
      });
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      doc.addImage(imgData, "PNG", 10, 2 + position, imgWidth, imgHeight);
      position = 0;
      let heightLeft = imgHeight - pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 10, 2 + position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      if (section !== sections[sections.length - 1]) {
        doc.addPage();
      }
    });

    await Promise.all(sectionPromises);

    doc.save("AllSections.pdf");
    setIsLoading(false);
  };

  const getApiCall = () => {
    axios
      .get(
        `${serverAddress}/episodeDetails/getEpisodeDetailsById?episode_id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setEpisodeData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getApiCall();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <Wrapper>
      <Card>
        <div ref={pdfDocumentRef}>
          <Button onClick={handleDownloadAll}>
            Download All as PDF
          </Button>
          <div id="episode-info">
            <EpisodeInfo episodeData={episodeData} isloading={isLoading} />
          </div>
          <Button
            onClick={() => downloadSectionAsPDF("episode-info", "EpisodeInfo")}
          >
            Download Episode Info as PDF
          </Button>
          <div id="episode-topics">
            <EpisodeTopics episodeData={episodeData} isloading={isLoading} />
          </div>
          <Button
            onClick={() =>
              downloadSectionAsPDF("episode-topics", "EpisodeTopics")
            }
          >
            Download Episode Topics as PDF
          </Button>
          <div id="episode-moments">
            <EpisodeMoments episodeData={episodeData} isloading={isLoading} />
          </div>
          <Button
            onClick={() =>
              downloadSectionAsPDF("episode-moments", "EpisodeMoments")
            }
          >
            Download Episode Moments as PDF
          </Button>
          <div id="episode-promotion">
            <EpisodePromotion episodeData={episodeData} isloading={isLoading} />
          </div>
          <Button
            onClick={() =>
              downloadSectionAsPDF("episode-promotion", "EpisodePromotion")
            }
          >
            Download Episode Promotion as PDF
          </Button>
          <div id="episode-assets">
            <EpisodeAssets episodeData={episodeData} isloading={isLoading} />
          </div>
          <Button
            onClick={() =>
              downloadSectionAsPDF("episode-assets", "EpisodeAssets")
            }
          >
            Download Episode Assets as PDF
          </Button>
        </div>
      </Card>
    </Wrapper>
  );
};

export default EpisodeData;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;

  .kendo-pdf-document h2 {
    text-decoration: underline;
    font-size: large;
    font-weight: bold;
  }
  .kendo-pdf-document p {
    font-size: medium;
    font-weight: bold;
  }
`;
