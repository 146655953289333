import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Table,  Card, Radio, Pagination } from "antd";
import { DeleteOutlined, EditTwoTone } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverAddress } from "../../Constant";
import { getToken, getUser } from "../../utils/Auth";
import PodcastTable from "../../components/podcast/PodcastTable";
import {
  ErrorNotification,
  SuccessNotification,
} from "../notification/Notification";
import { RightOutlined } from '@ant-design/icons';

const Season = (props) => {
  const dispatch = useDispatch();
  const podcastId = props.location.state;
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState([]);
  const [seasonData, setSeasonData] = useState([]);
  let season = useSelector((state) => state.common.season);
  const podcast = useSelector((state) => state.common.podcast);
  const sortedSeasonData = seasonData.sort((a, b) => a.Season_No - b.Season_No);
  const [episodes, setEpisodes] = useState([]);
  const { seasonId } = props.location.state;

  const getSeason = async (podcastId) => {
    await axios
      .get(
        `${serverAddress}/seasons/get_Seasons_by_PodcastId?podcast_Id=${podcastId}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setLoader(false);
        dispatch({
          type: "SET_SEASON",
          payload: {
            season: res?.data?.seasons,
          },
        });
        

        setSeasonData(res?.data?.seasons);
      })
      .catch((err) => {
        ErrorNotification(err);
        setLoader(false);
      });
    };
  useEffect(() => {
    getSeason(podcastId , seasonId);
  }, [podcastId ,seasonId]);

  useEffect(() => {
    setSearchValue(season);
  }, [podcast]);

  const columns = [
    {
      title: "Podcast Name",
      dataIndex: "podcast_name",
      key: "podcast_name",
    },
    {
      title: "Season No #",
      dataIndex: "Season_No",
      key: "Season_No",
    },
    {
      title: "No of Episodes",
      dataIndex: "Count",
      key: "Count",
    },
  ];

  const userRole = getUser();

  const handleNewSubUser = () => {
    history.push("/seasonCreate");
  };

  const handlePodcastClick = (seasonId) => {
    history.push({
      pathname: '/episodes',
      state: { seasonId } 
    });
  };

  return (
    <>
      <Card
        
        title={<span style={{ color: '#FF6A3C' , fontWeight: "bold" }}>{seasonData[0]?.podcast_name} <RightOutlined />  Seasons</span>}
        extra={
          userRole?.role !== 3 && (
            <Radio.Button onClick={handleNewSubUser}>Add Season</Radio.Button>
          )
        }
      >
        <StyledTable
          tableLayout={"unset"}
          pagination={<Pagination defaultCurrent={2} total={season?.length} />}
          columns={columns}
          dataSource={sortedSeasonData}
          key={season?.id}
          loading={loader}
          onRow={(record) => {
            return {
              onClick: () => handlePodcastClick(record.Season_No),
            };
          }}
        />
      </Card>
    </>
  );
};

export default Season;

// Add CSS media queries for responsiveness
const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }
 

  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    .ant-table-thead > tr > th {
      /* Modify styles for table headers */
    }
    .ant-table {
      overflow-x: auto;
    }
  }
`;
