import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background: white;
  overflow-y: hidden;
  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }

  .bodyContent {
    display: flex;
    align-items: center;
    height: calc(100% - 140px);
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #1a2138;
      font-weight: 800;
      font-size: 18px;
    }
  }

  .bottomSection {
    width: 40%;
    display: flex;
    flex-direction: column;

    button {
      width: 200px;
      height: 45px;
      background: #1a2138;
      align-self: end;
      border: none;
      border-radius: 5px;
      font-weight: bold;
    }
    button:hover {
      background: #ff6a3c;
    }
  }
  .inputField {
    width: 40%;
    margin-top: 20px;
  }
  .ant-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: black;
    font-weight: 500;
  }
  .ant-btn[disabled] {
    color: white;
  }
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-weight: 500;
  }
  .ant-form {
    display: contents;
  }
`;
