import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "../../axois/Axois";
import { getToken } from "../../utils/Auth";
import { ErrorNotification } from "../index";

const EpisodeInfo = ({ episodeData }) => {
  const [info, setInfo] = useState({});

  //API CALL FUNCTION

  return (
    <Wrapper>
      <h3 className="heading">Episode Info</h3>
      <div className="flex">
        <div>
          <p className="field">
            Episode ID:<p className="data-text">{episodeData?.episode_id}</p>
          </p>
        </div>
        <div>
          <p className="field">
            Podcast:<p className="data-text">{episodeData?.podcast}</p>
          </p>
        </div>

        <div>
          <p className="field">
            Season Number:
            <p className="data-text"> {episodeData?.season_number}</p>
          </p>
        </div>
        <div>
          <p className="field">
            Episode Number:
            <p className="data-text">{episodeData?.episode_number}</p>
          </p>
        </div>
      </div>

      <p className="field">Episode Title:</p>
      <p className="data-text">{episodeData?.episode_title}</p>
      <p className="field">Bio:</p>
      <p
        className="data-text"

        // dangerouslySetInnerHTML={{ __html: episodeData?.bio }}
      >
        {episodeData?.bio}
      </p>
      <p className="field">Something Interesting:</p>
      <p className="data-text">{episodeData?.something_interesting}</p>
      <p className="field">Special Links:</p>
      {episodeData?.special_links?.map(({ link }, index) => {
        return (
          <p className="data-text" key={index}>
            {link}
          </p>
        );
      })}
      <p className="field">Intro Notes:</p>
      <p
        className="data-text"
        dangerouslySetInnerHTML={{ __html: episodeData?.intro_notes }}
      ></p>
      {/* <p className="field">Sponser episodeData:</p>
      <p className="data-text">{episodeData?.sponser_episodeData}</p> */}
      <p className="field">Dry Read Commercial:</p>
      <p className="data-text">{episodeData?.dry_read_commercial}</p>
      <p className="field">Author Book Info:</p>
      <p className="data-text">{episodeData?.author_book_info}</p>
      <p className="field">Author Book Link:</p>
      <p className="data-text">{episodeData?.author_book_link}</p>
      <p className="field">Book Notes:</p>
      <p className="data-text">{episodeData?.book_notes}</p>
    </Wrapper>
  );
};

export default EpisodeInfo;

const Wrapper = styled.div`
  padding: 10px 10px;
  // background: #e7dada;
  .heading {
    border-bottom: 1px solid rgba(53, 86, 129, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-family: "Ultra", sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    text-shadow: 0 2px white, 0 3px #777;
  }
  .field {
    // margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    font-family: "Orienta", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    // text-decoration: underline;
  }

  .flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, 100px);
  }
  .data-text {
    all: initial;
    padding: 0px 10px;
    white-space: break-spaces;
  }
`;
