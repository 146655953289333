import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Input, Button, message, Select } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getToken, setEpisodeId } from "../../utils/Auth";
import { serverAddress } from "../../Constant";
import { ErrorNotification, SuccessNotification } from "../../components";

const SeasonCreate = () => {
  const [seasonData, setSeasonData] = useState({
    podcast_Id: "",
    Podcast_name: "",
    Season_No: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const [availableSeasons, setAvailableSeasons] = useState([]);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeasonData({
      ...seasonData,
      [name]: value,
    });
  };

  const getPodcasts = async () => {
    try {
      const response = await axios.get(
        `${serverAddress}/podcast/getAllPodcast`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      const podcastList = response?.data;
      setPodcasts(podcastList);
    } catch (err) {
      ErrorNotification(err);
    }
  };

  const fetchAvailableSeasons = async (selectedPodcastId) => {
    try {
      const response = await axios.get(
        `${serverAddress}/seasons/available_seasons?podcast_Id=${selectedPodcastId}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      const availableSeasonsList = response?.data?.available_season;
      setAvailableSeasons(availableSeasonsList);
    } catch (err) {
      ErrorNotification(err);
    }
  };
  

  const handlePodcastChange = (selectedPodcast) => {
    const selectedPodcastDetail = podcasts.find(podcast => podcast.Podcast_name === selectedPodcast)
    
      if (selectedPodcastDetail) {
    setSeasonData({
      ...seasonData,
      Podcast_name: selectedPodcast,
      podcast_Id:selectedPodcastDetail.id
    });
    fetchAvailableSeasons(selectedPodcastDetail.id);
  }
  };
  const AddSeason = async (event) => {
    event.preventDefault();
    const { Podcast_name, Season_No,podcast_Id } = seasonData;
  
    if (Podcast_name === "" || Season_No === "") {
      message.error("Please fill all fields");
      return;
    }
  
    if (isNaN(Season_No)) {
      message.error("Season number must be a valid number");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(
        `${serverAddress}/seasons/add_Seasons`,
        {
          podcast_name:Podcast_name,
          podcast_Id:podcast_Id,
          Season_No: parseInt(Season_No), 
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setEpisodeId(response.data.seasons.id);
      SuccessNotification("Season created successfully");
      history.push({ pathname: "/season", state: response?.data?.seasons.podcast_Id });
    } catch (err) {
      message.error("Invalid Information, please check again");
      ErrorNotification(err);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    getPodcasts();
  }, []);

  return (
    <Wrapper>
      <Card>
        <form onSubmit={AddSeason}>
          <div className="loginMain">
            <div className="bodyContent">
              <h2>Add New Season</h2>
              <div className="inputField">
              <label>Select a Podcast:</label>
                <Select
                className="ant-input"
                  placeholder="Select a Podcast"
                  onChange={handlePodcastChange}
                  value={seasonData.Podcast_name}
                  style={{ width: "100%" }}
                >
                  {podcasts.map((podcast) => (
                    <Select.Option
                      key={podcast.id}
                      value={podcast.Podcast_name}
                    >
                      {podcast.Podcast_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="inputField">
              <label>Select a Season:</label>
                <Select
                className="ant-input"
                  placeholder="Select a Season"
                  onChange={(value) =>
                    setSeasonData({ ...seasonData, Season_No: value })
                  }
                  value={seasonData.Season_No}
                  style={{ width: "100%" }}
                >
                  
                      <Select.Option  value={availableSeasons}>
                        {availableSeasons}
                      </Select.Option>
                </Select>
              </div>

              <br />

              <div className="bottomSection">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={!seasonData.Podcast_name || !seasonData.Season_No}
                >
                  Add Season
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </Wrapper>
  );
};

export default SeasonCreate;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  color: white;

  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }

  .bodyContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #000;
      font-weight: 800;
      font-size: 25px;
    }
  }

  .bottomSection {
    width: 40%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      width: 200px;
      height: 45px;
      align-self: end;
      border: none;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      background: #1a2138;

      &:hover {
        background: #ff6a3c;
        border: 1px solid white;
      }
    }
  }

  .inputField {
    width: 40%;
    margin-top: 20px;
  }
  .ant-select-selector{
    border: none !important;
    height: 24px !important;
}
  .ant-select {
    width: 100%; /* Set the width of the Select component */
  }
  .ant-select-dropdown {
    border: none;
    width: 100%; /* Set the width of the dropdown menu */
  }

  .ant-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: black;
    font-weight: 500;
    transition: all 0.5s ease-out;

    &:not(.ant-input-affix-wrapper > input.ant-input):focus,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused:focus {
      border: 1px solid black;
      box-shadow: 0 0 0 1px #1a2138 !important;
      transition: all 0.5s ease-out;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border: 1px solid orange !important;
    }

    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      color: #000;
      font-weight: 500;
    }
  }
`;
