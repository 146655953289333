import React from "react";
import { Wrapper } from "./Dashboard.styled";
import AnaylticCard from "../../../../components/Analytics/Card";
import DashboardList from "../../../../components/dashboardList/DashboardList";
import { getUser } from "../../../../utils/Auth";

export default function IndexPage() {
  const user = getUser();
  const { firstname, lastname } = getUser().user;
  const [loader, setLoader] = React.useState(true);
  const [role, setRole] = React.useState(0);
  React.useEffect(() => {
    if (user) {
      setRole(user?.role);
      setLoader(false);
    }
  }, [user]);

  return (
    <Wrapper>
      <h2 className="heading">
        <span> Hi {firstname} , </span>your analytics are all set
        {/* {lastname} */}
      </h2>
      {!loader && <AnaylticCard role={role} />}
      <br />
      <br />

      <DashboardList />
    </Wrapper>
  );
}
