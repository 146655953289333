import React, { useState } from "react";
import styled from "styled-components";
import {
  Table,
  Space,
  Card,
  Pagination,
  Select,
  Input,
  Popconfirm,
} from "antd";
import { getEpisodeId, getToken, setEpisodeId } from "../../utils/Auth";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axois/Axois";
import { serverAddress } from "../../Constant";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import {
  ErrorNotification,
  SuccessNotification,
} from "../notification/Notification";

const { Search } = Input;
const { Option } = Select;

const EpisodeTable = () => {
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [select, setSelect] = useState("");
  const [searchValue, setSearchValue] = useState([]);
  const dispatch = useDispatch();
  const podcast = useSelector((state) => state.common.podcast);
  let episodes = useSelector((state) => state.common.episodes);

  //API CALLS
  const getEpisodes = () => {
    axios
      .get(`${serverAddress}/episode/getAllEpisodes`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        setLoader(false);
        dispatch({
          type: "SET_EPISODES",
          payload: {
            episodes: res?.data,
          },
        });
      })
      .catch((err) => {
        console.log("error message==>", err);
        setLoader(false);
      });
  };

  const onDelete = (id, e) => {
    e.preventDefault();
    setLoader(true);
    deleteEpisode(id);
  };

  const deleteEpisode = (id) => {
    const qs = require("qs");
    axios
      .get(`${serverAddress}/episode/deleteEpisodebyId?id=${id}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        if (res.data.success) {
          episodes = episodes.filter((item) => item.id !== id);
          SuccessNotification("Episode Deleted.");
          getEpisodes();
        } else {
          setLoader(true);
          ErrorNotification("Error deleting episode");
        }
      })
      .catch((err) => {
        console.log("error message==>", err.message);
        setLoader(true);
        ErrorNotification("Error deleting episode");
      });
  };

  useEffect(() => {
    getEpisodes();
    setLoader(true);
  }, []);
console.log("vvv",episodes);


  const handleSelect = (value) => {
    setSelect(value);
  };

  const onChange = (e) => {
    const filteredEpisodes = episodes.filter((x) => {
      return select === "name"
        ? x[select]?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) !== -1
        : x[select]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(e.target.value.toLowerCase()) !== -1;
    });
    setSearchValue(filteredEpisodes);
  };

  useEffect(() => {
    setSearchValue(episodes);
  }, [episodes]);

  const searchSelect = [
    {
      title: "Episode Name",
      key: "episode_name",
    },
    {
      title: "Guest Name",
      key: "guest_name",
    },
  ];

  const columns = [
    {
      title: "Episode Name",
      dataIndex: "episode_name",
      key: "episode_name",
    },
    {
      title: "Podcast Name",
      dataIndex: "Podcast_name",
      key: "Podcast_name",
    },
    {
      title: "Season No",
      dataIndex: "Season_No",
      key: "Season_No",
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_url",
      key: "thumbnail",
      render: (thumbnail_url) => (
        <img
          className="image"
          src={thumbnail_url}
          alt="Thumbnail"
          style={{ width: "50px", height: "50px" , borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      render: (_, data) => {
        return (
          <span>
            {new Date(data?.created_at._seconds * 1000)?.toLocaleString()}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (value, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setEpisodeId(record?.id);
              history.push({
                pathname: "/episodeData",
                id: record?.id,
                name: record?.name,
              });
            }}
          >
            <Space>View More Details</Space>
          </a>
          /
          <a
            onClick={() => {
              setEpisodeId(record?.id);
              history.push({
                pathname: "/updateEpisode",
                id: record?.id,
              });
            }}
          >
            <Space>Edit Details</Space>
          </a>
          <Space>
            <Popconfirm
              title="Are you sure to delete this Episode"
              onConfirm={(e) => {
                onDelete(record.id, e);
              }}
              okText="Delete"
              okType="danger"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

   return (
    <StyledWrapper>
      <Card
       title={<span style={{ color: '#FF6A3C' , fontWeight: "bold" }}> Episodes</span>}
        extra={
          <div
            className="searchBar"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Select
              style={{ width: 120, marginRight: 10 }}
              onChange={handleSelect}
              value={select}
            >
              {searchSelect.map((item) => {
                return <Option value={item.key}>{item.title}</Option>;
              })}
            </Select>
            <Input
              style={{ width: "300px", borderRadius: "8px" }}
              placeholder="Search"
              onChange={onChange}
              enterButton
            />
          </div>
        }
      >
        <StyledTable
          tableLayout={"unset"}
          pagination={
            <Pagination defaultCurrent={5} total={episodes?.length} />
          }
          columns={columns}
          dataSource={searchValue}
          key={episodes?.id}
          loading={loader}
        />
      </Card>
    </StyledWrapper>
  );
};

export default EpisodeTable;

const StyledWrapper = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }

  .ant-input-wrapper {
    .ant-input-group {
      ant-btn-primary {
        background: #1a2138;
      }
    }
  }

  @media (max-width: 768px) {
    .ant-table {
      overflow-x: auto;
    }
  }
`;