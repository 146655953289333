import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Upload, Button, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../notification/Notification";
import { getToken, getEpisodeId, removeEpisodeId } from "../../../utils/Auth";
import axios from "../../../axois/Axois";
import { useLocation } from "react-router-dom";
import Loading from "../../loading/Loading";
import { serverAddress } from "../../../Constant";

const EpisodeAsset = ({ tab, setTab }) => {
  const location = useLocation();
  const history = useHistory();
  let id = getEpisodeId();
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState({});
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState({});
  const [episodeImage, setepisodeImage] = useState({
    headshotImg1: "",
    headshotImg2: "",
    headshotImg3: "",
    speakerImage1: "",
    speakerImage2: "",
    bioImage1: "",
    bioImage2: "",
  });
  const [SpeakerImage, setSpeakerImage] = useState(null);
  const [BioImage, setBioImage] = useState(null);
  const [formData, setFormData] = useState({});
  console.log("formData", formData);
  //HANDLE SUBMIT
  console.log(episodeImage);
  const next = () => {
    setIsLoading(true);
    postApiCall();
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
    // setTab(4);
  };

  //API CALLS
  const postApiCall = () => {
    axios
      .post(
        `${serverAddress}/episodeDetails/editEpisodeDetails`,
        { ...episodeImage, episode_id: id },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        // setIsBtnLoading(false);
        SuccessNotification("Episode info saved successfully");
        setTab(5);
      })
      .catch((err) => {
        ErrorNotification("Error saving episode info");
        console.log(err);
        // setIsBtnLoading(false);
      });
  };

  const getApiCall = () => {
    axios
      .get(
        `${serverAddress}/episodeDetails/getEpisodeDetailsById?episode_id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        const _data = res.data;
        setIsLoading(false);
        setepisodeImage({
          ...episodeImage,
          headshotImg1:
            _data.headshotImg1 === undefined ? "" : _data.headshotImg1,
          headshotImg2:
            _data.headshotImg2 === undefined ? "" : _data.headshotImg2,
          headshotImg3:
            _data.headshotImg3 === undefined ? "" : _data.headshotImg3,
          speakerImage1:
            _data.speakerImage1 === undefined ? "" : _data.speakerImage1,
          speakerImage2:
            _data.speakerImage2 === undefined ? "" : _data.speakerImage2,
          bioImage1: _data.bioImage1 === undefined ? "" : _data.bioImage1,
          bioImage2: _data.bioImage2 === undefined ? "" : _data.bioImage2,
        });
      })
      .catch((err) => {
        ErrorNotification("Request Failed");
        setIsLoading(false);
        console.log(err);
      });
  };
  console.log(assets?.headshots?.["1"]);
  let headshot1 = assets?.headshots?.["1"];

  const prev = () => {
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
    setTab(3);
  };
  useEffect(() => {
    getApiCall();
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (headshot1) setLoading(false);
  }, [assets]);

  const headshots = {
    beforeUpload: (file) => {
      const fileSize = file.size / 1024 / 1024 < 10;
      console.log(fileSize);
      if (!fileSize) {
        ErrorNotification("File is greater than 10MB!");
      }
      const PNG = file.type === "image/png";
      const JPEG = file.type === "image/jpeg";
      if (!PNG && !JPEG) {
        ErrorNotification("Only PNG and JPEG file required");
      }

      return fileSize || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        SuccessNotification("File Uploaded Successful");
      } else if (info.file.status === "error") {
        ErrorNotification("File uploading failed");
      }
    },
    showUploadList: false,
  };

  const speakerSheet = {
    beforeUpload: (file) => {
      const fileSize = file.size / 1024 / 1024 < 5;
      console.log(fileSize);
      if (!fileSize) {
        ErrorNotification("File is greater than 5MB!");
      }

      return fileSize || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        SuccessNotification("File Uploaded Successful");
      } else if (info.file.status === "error") {
        ErrorNotification("File uploading failed");
      }
    },
    listType: "picture",
  };
  const bioDocument = {
    beforeUpload: (file) => {
      const fileSize = file.size / 1024 / 1024 < 15;
      console.log(fileSize);
      if (!fileSize) {
        ErrorNotification("File is greater than 15MB!");
      }

      return fileSize || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status === "done") {
        SuccessNotification("File Uploaded Successful");
      } else if (info.file.status === "error") {
        ErrorNotification("File uploading failed");
      }
    },
    listType: "picture",
  };

  const HeadshotUploadComponent = ({ headshotImg, headshotName }) => {
    const [loading, setLoading] = React.useState(false);

    return (
      <Upload
        {...headshots}
        id="thumbnail"
        listType="picture"
        type="file"
        accept=".png,.jpeg,.jpg"
        beforeUpload={(file) => {
          setLoading(true);
          const imageformData = new FormData();
          imageformData.append("file", file);
          console.log(imageformData);
          axios
            .post(`${serverAddress}/episode/Upload`, imageformData, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
              setThumbnail(response.data.signedUrl[0]);
              const _headshotImg = headshotImg;
              console.log(_headshotImg);
              setepisodeImage({
                ...episodeImage,
                [headshotName]: response.data.signedUrl[0],
              });

              setLoading(false);
            })
            .catch((error) => {
              return false;
              // Handle errors
            });

          return false;
        }}
      >
        {loading ? (
          <Spin />
        ) : headshotImg !== "" ? (
          <img src={headshotImg} width="100px" />
        ) : (
          <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
        )}
      </Upload>
    );
  };

  const SpeakerUploadComponent = ({ speakerImage, speakerName }) => {
    const [loading, setLoading] = React.useState(false);

    return (
      <Upload
        {...speakerSheet}
        id="thumbnail"
        listType="picture"
        type="file"
        accept=".png,.jpeg,.jpg"
        beforeUpload={(file) => {
          setLoading(true);
          const imageformData = new FormData();
          imageformData.append("file", file);
          console.log(imageformData);
          axios
            .post(`${serverAddress}/episode/Upload`, imageformData, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
              setThumbnail(response.data.signedUrl[0]);
              const _speakerImage = speakerImage;
              console.log(_speakerImage);
              setepisodeImage({
                ...episodeImage,
                [speakerName]: response.data.signedUrl[0],
              });

              setLoading(false);
            })
            .catch((error) => {
              return false;
            });

          return false;
        }}
      >
        {loading ? (
          <Spin />
        ) : speakerImage !== "" ? (
          <img src={speakerImage} width="100px" />
        ) : (
          <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
        )}
      </Upload>
    );
  };
  const BioUploadComponent = ({ bioImage, bioName }) => {
    const [loading, setLoading] = React.useState(false);

    return (
      <Upload
        {...speakerSheet}
        id="thumbnail"
        listType="picture"
        type="file"
        accept=".png,.jpeg,.jpg"
        beforeUpload={(file) => {
          setLoading(true);
          const imageformData = new FormData();
          imageformData.append("file", file);
          console.log(imageformData);
          axios
            .post(`${serverAddress}/episode/Upload`, imageformData, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);
              setThumbnail(response.data.signedUrl[0]);
              const _bioImage = bioImage;
              console.log(_bioImage);
              setepisodeImage({
                ...episodeImage,
                [bioName]: response.data.signedUrl[0],
              });

              setLoading(false);
            })
            .catch((error) => {
              return false;
              // Handle errors
            });

          return false;
        }}
      >
        {loading ? (
          <Spin />
        ) : bioImage !== "" ? (
          <img src={bioImage} width="100px" />
        ) : (
          <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
        )}
      </Upload>
    );
  };

  return isLoading ? (
    <h1>Loading....</h1>
  ) : (
    <Wrapper>
      <div className="tab-navigate">
        <Row>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Button onClick={prev}>Prev Form</Button>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
            <Button className="submit" onClick={next} loading={isLoading}>
              Next Form
            </Button>
          </Col>
        </Row>
      </div>
      <br />

      <h2>HeadShots:</h2>
      <span>Only " .jpg, .png, .pdf" Files</span>
      <Row gutter={[15, 15]}>
        {[1, 2, 3].map((item, index) => (
          <Col>
            <div style={{ display: "flex" }}>
              <HeadshotUploadComponent
                key={index}
                headshotImg={
                  index == 0
                    ? episodeImage.headshotImg1
                    : index == 1
                    ? episodeImage.headshotImg2
                    : episodeImage.headshotImg3
                }
                headshotName={
                  index == 0
                    ? "headshotImg1"
                    : index == 1
                    ? "headshotImg2"
                    : "headshotImg3"
                }
              />
            </div>
          </Col>
        ))}
      </Row>

      <br />
      <br />

      <h2>Speaker Sheet:</h2>
      <span>Only ".doc, .docx , .pdf" Files</span>
      <Row gutter={[15, 15]}>
        {[1, 2].map((item, index) => (
          <Col>
            <div style={{ display: "flex" }}>
              <SpeakerUploadComponent
                key={index}
                speakerImage={
                  index == 0
                    ? episodeImage.speakerImage1
                    : index == 1
                    ? episodeImage.speakerImage2
                    : ""
                }
                speakerName={
                  index == 0
                    ? "speakerImage1"
                    : index == 1
                    ? "speakerImage2"
                    : ""
                }
              />
            </div>
          </Col>
        ))}
      </Row>

      <br />
      <br />

      <h2>Bio Document:</h2>
      <span>(Only ".doc, .docx , .pdf" Files)</span>

      <Row gutter={[15, 15]}>
        {[1, 2].map((item, index) => (
          <Col>
            <div style={{ display: "flex" }}>
              <BioUploadComponent
                key={index}
                bioImage={
                  index == 0
                    ? episodeImage.bioImage1
                    : index == 1
                    ? episodeImage.bioImage2
                    : ""
                }
                bioName={
                  index == 0 ? "bioImage1" : index == 1 ? "bioImage2" : ""
                }
              />
            </div>
          </Col>
        ))}
      </Row>
      <br />
      <br />
      <div className="tab-navigate">
        <Row>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Button onClick={prev}>Prev Form</Button>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
            <Button className="submit" onClick={next} loading={isLoading}>
              Next Form
            </Button>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default EpisodeAsset;

const Wrapper = styled.div`
  .submit {
    text-align: end;
  }
  .tab-navigate {
    .ant-btn {
      background: #1a2138;
      color: white;
    }
  }
`;
