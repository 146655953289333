const initState = {
  isCollapse: false,
  SubUsers: [],
  episodes: [],
  podcaster: [],
  podcast: [],
  
  userIsAdd: false,
  id: undefined,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_COLLAPSE":
      return {
        ...state,
        isCollapse: action.payload.isCollapse,
      };
    case "SET_SUB_USERS":
      return {
        ...state,
        SubUsers: action.payload.SubUsers,
      };
    case "USER_IS_ADD":
      return {
        ...state,
        userIsAdd: action.payload.userIsAdd,
      };
    case "PODCASTER_USERS":
      return {
        ...state,
        podcaster: action.payload.podcaster,
      };

    case "SET_EPISODES":
      return {
        ...state,
        episodes: action.payload.episodes,
      };
    case "SET_PODCAST":
      return {
        ...state,
        podcast: action.payload.podcast,
      };
    case "SET_SEASON":
      return {
        ...state,
        season: action.payload.season,
      };

    case "SET_ID":
      return {
        ...state,
        id: action.payload.id,
      };

    default:
      return state;
  }
};
