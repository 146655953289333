import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Spin } from "antd";
import styled from "styled-components";
import InputTables from "../../inputTable/InputTables";
import { getEpisodeId } from "../../../utils/Auth";
import axios from "../../../axois/Axois";
import { ErrorNotification, SuccessNotification } from "../../index";
import { getToken } from "../../../utils/Auth";
import { useLocation } from "react-router-dom";
import { serverAddress } from "../../../Constant";

const { TextArea } = Input;

const EpisodeMoments = ({ tab, setTab }) => {
  const location = useLocation();
  let id = getEpisodeId();
  const [formData, setFormData] = useState({
    episode_id: id,
    moment_table: [
      ...[1, 2, 3, 4, 5, 6].map((value) => {
        return {
          momentNo: value,
          moment: "",
          timeStamp: "",
          quoteWorth: false,
          audiogramWorthy: false,
        };
      }),
    ],
    moment_notes: "",
  });
  const [richData, setRichData] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [isBtnloading, setIsBtnLoading] = useState(false);
  console.log("formData", formData);
  //HANDLE CHANGE

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    console.log(e.target);
  };

  //API CALLS

  const postApiCall = () => {
    axios
      .post(
        `${serverAddress}/episodeDetails/editEpisodeDetails`,
        { ...formData, topics_to_cover: richData },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        console.log(res);
        setIsBtnLoading(false);
        SuccessNotification("Episode info saved successfully");
        setTab(6);
      })
      .catch((err) => {
        ErrorNotification("Error saving episode info");
        console.log(err);
        setIsBtnLoading(false);
      });
  };

   const getApiCall = () => {
    axios
      .get(
        `${serverAddress}/episodeDetails/getEpisodeDetailsById?episode_id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setFormData({
          ...formData,
          moment_table: res.data.moment_table === undefined
          ? formData.moment_table
          : res.data.moment_table,
          moment_notes: res.data.moment_notes === undefined
          ? formData.moment_notes
          : res.data.moment_notes,

        });
        setRichData(res.data.moment_table === undefined
          ? formData.moment_table
          : res.data.moment_table);
      })
      .catch((err) => {
        ErrorNotification("Request Failed");
        setIsLoading(false);
        console.log(err);
      });
  };

  //BUTTON FUNCTION

  useEffect(() => {
    getApiCall();
  }, []);

  const prev = () => {
    setTab(4);
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const next = () => {
    postApiCall();
    setIsLoading(true);
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Wrapper>
      {isloading ? (
        <Spin />
      ) : (
        <>
          <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                <Button onClick={prev}>Prev Form</Button>
              </Col>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
                <Button
                  className="submit"
                  onClick={next}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>
          <br />

          {/* <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input Episode ID!",
                },
              ]}
            >
              Episode ID:
              <Input
                // placeholder="Enter Name "
                value={formData.episode_id}
                id="episode_id"
              />
            </Form.Item>
          </Col>
        </Row> */}

          <InputTables formData={formData} setFormData={setFormData} />
          <br />
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <Form.Item>
                <div style={{ whiteSpace: "pre" }}>Show Notes:</div>
                <TextArea
                  rows={10}
                  placeholder="Enter Moments Notes "
                  id="moment_notes"
                  onChange={handleChange}
                  typeof="text"
                  // defaultValue={formData?.moment_notes}
                  value={formData?.moment_notes}
                />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <br />
          <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                <Button onClick={prev}>Prev Form</Button>
              </Col>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
                <Button
                  className="submit"
                  onClick={next}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default EpisodeMoments;

const Wrapper = styled.div`
  .submit {
    text-align: end;
  }
  .tab-navigate {
    .ant-btn {
      background: #1a2138;
      color: white;
    }
  }
`;
