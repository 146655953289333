import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 100%;
  background: #1a2138;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.7s;
  transition-timing-function: ease-in-out;

  .hamburger {
    margin-bottom: 10px;
    padding: 20px;
    cursor: pointer;
    width: 75px;
    img {
      width: 100%;
    }
  }
  .logo {
    margin-bottom: 10px;
    padding: 20px;
    cursor: pointer;
    width: 190px;
    img {
      width: 100%;
    }
  }

  .menu-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .logout {
      .sidebaropen,
      .sidebarclose {
        color: white;
      }
    }
  }

  .sidebarclose {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
    color: #9799aa;
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
    p {
      display: none;
    }
  }

  .sidebaropen {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0px 15px 30px;
    color: #9799aa;
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
    p {
      font-size: 12px;
      font-weight: 600;
      margin-left: 10px;
      margin-bottom: 0px;
      font-weight: 600;
    }
  }

  .sidebar-active {
    color: #fff;
    background: #ff6a3c;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;
