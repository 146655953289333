import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Card, Input, Button, Upload, Select, message, Form } from "antd";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { getToken, setEpisodeId, getEpisodeId, getUser } from "../../../utils/Auth";
import { UploadOutlined } from "@ant-design/icons";
import { ErrorNotification } from "../../notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import { serverAddress } from "../../../Constant";

const EpisodeCreate = () => {
  const [episodeData, setEpisodeData] = useState({
    name: "",
    guest_names: [],
    file: "",
    selectedPodcast: "",
    selectedSeason: "",
    podcast_Id: "",
  });
  console.log("bbb",episodeData)
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const [availableSeasons, setAvailableSeasons] = useState([]);
  const sortedSeasonData = availableSeasons.sort((a, b) => a.Season_No - b.Season_No);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.id || getEpisodeId();
  const userId = getUser().user.id;

  console.log("ccc",availableSeasons);
  useEffect(() => {
    getPodcasts();
  }, []);

  const handleChange = (e) => {
    setEpisodeData({
      ...episodeData,
      [e.target.id]: e.target.value,
    });
  };

  const getPodcasts = async () => {
    try {
      const response = await axios.get(`${serverAddress}/podcast/getAllPodcast`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setPodcasts(response?.data);
    } catch (err) {
      ErrorNotification(err);
    }
  };
  const getSeasonsByPodcastId = async (podcastId) => {
    try {
      const response = await axios.get(
        `${serverAddress}/seasons/get_Seasons_by_PodcastId?podcast_Id=${podcastId}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      setAvailableSeasons(response?.data?.seasons);
    } catch (err) {
      ErrorNotification(err);
    }
  };


  const handleGuestsName = (e) => {
    setEpisodeData({
      ...episodeData,
      guest_names: e.target.value.split(","),
    });
  };

  const AddEpisode = async (event) => {
    const formData = new FormData(event.target);
    const data = {
      guest_name: formData.get("name"),
      episode_name: formData.get("guest_name"),
      thumbnail_url: episodeData.file,
      Podcast_name: episodeData.selectedPodcast,
      Season_No: episodeData.selectedSeason,
      podcast_Id: episodeData.podcast_Id
    };

    if (data.episode_name === "" || data.guest_name.length === 0 || data.thumbnail_url === "" || data.Podcast_name === ""|| data.Season_No === "") {
      ErrorNotification("Please fill all fields");
      setIsLoading(false);
      return;
    }

    try {
      const res = await axios.post(`${serverAddress}/episode/add_Episodes`, data, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      dispatch({
        type: "SET_EPISODES",
        payload: {
          episodes: res?.data?.episode,
        },
      });
      await setEpisodeId(res.data.episode.id);
      history.push({
        pathname: "/newepisode",
        id: res.data.episode.id,
        episodeData:episodeData
      });
      setIsLoading(false);
    } catch (err) {
      ErrorNotification("Invalid Information, please check again");
      setIsLoading(false);
    }
  };

  const handlePodcastChange = (selectedPodcast) => {
  
    const selectedPodcastDetail = podcasts.find((podcast) => podcast.Podcast_name === selectedPodcast);
    if (selectedPodcastDetail) {
      getSeasonsByPodcastId(selectedPodcastDetail.id);
    }
    setEpisodeData({
      ...episodeData,
      selectedPodcast: selectedPodcast,
      podcast_Id: selectedPodcastDetail.id,
      selectedSeason: "", 
    });
  };
  
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    AddEpisode(event);
  };

  return (
    <Wrapper>
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="loginMain">
            <div className="bodyContent">
              <h2>Add New Episode</h2>
              <div className="inputField">
              <label>Select a Podcast:</label>
                <Select
                  className="ant-input"
                  placeholder="Select a Podcast"
                  onChange={handlePodcastChange}
                  value={episodeData.selectedPodcast}
                  style={{ width: "100%" }}
                >
                  {podcasts.map((podcast) => (
                    <Select.Option key={podcast.id} value={podcast.Podcast_name}>
                      {podcast.Podcast_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="inputField">
              <label>Select a Season:</label>
                <Select
                  className="ant-input"
                  placeholder="Select a Season"
                  onChange={(value) =>{
                    setEpisodeData({ ...episodeData, selectedSeason: value })
                  }}
                  value={episodeData.selectedSeason}
                  style={{ width: "100%" }}
                >
                  {sortedSeasonData?.map((season) => (
                    <Select.Option key={season} value={season?.Season_No}>
                      {season?.Season_No}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="inputField">
              <label>Enter a Episode Name:</label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Episode Name"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="inputField">
              <label>Enter a Guest Name:</label>
                <Input
                  id="guest_name"
                  name="guest_name"
                  placeholder="Guest Name"
                  type="text"
                  onChange={handleGuestsName}
                />
              </div>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <Upload
                  id="thumbnail"
                  name="thumbnail"
                  listType="picture"
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  beforeUpload={(file) => {
                    const imageformData = new FormData();
                    imageformData.append("file", file);

                    axios
                      .post(`${serverAddress}/episode/Upload`, imageformData, {
                        headers: {
                          Authorization: `Bearer ${getToken()}`,
                          "Content-Type": "multipart/form-data",
                        },
                      })
                      .then((response) => {
                        setEpisodeData({
                          ...episodeData,
                          file: response.data.signedUrl[0],
                        });
                      })
                      .catch((error) => {
                        // Handle errors
                      });

                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined/>}>Upload Thumbnail</Button>
                </Upload>
              </div>

              <div className="bottomSection">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={episodeData.file === ""}
                >
                  Create Episode
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </Wrapper>
  );
};

export default EpisodeCreate;




const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  color: white;
  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }

  .bodyContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #000;
      font-weight: 800;
      font-size: 25px;
    }
  }
  .ant-select-selector{
    height: 24px !important;
    border: none !important;
  }
  .ant-select-selection-search{
  border: none !important;
box-shadow: none !important;
}
  .bottomSection {
    width: 40%;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    margin-top: 30px;
    button {
      width: 200px;
      height: 45px;
      align-self: end;
      border: none;
      color: white;

      border-radius: 5px;
      font-weight: bold;
      background: #1a2138;
    }
    button:hover {
      background: #ff6a3c;
      border: 1px solid white;
    }
  }
  .inputField {
    width: 40%;
    margin-top: 20px;
  }
  .ant-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: black;
    font-weight: 500;
    transition: all 0.5s ease-out;
  }
  .ant-input:not(.ant-input-affix-wrapper > input.ant-input):focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused:focus {
    border: 1px solid black;
    box-shadow: 0 0 0 1px #1a2138 !important;
    transition: all 0.5s ease-out;
  }
  .ant-input-affix-wrapper > input.ant-input {
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    /* border: 1px solid orange !important; */
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    box-shadow: none !important;
    border: none !important;
  }
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-weight: 500;
  }
`;
