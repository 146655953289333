import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Table, Space, Card, Radio, Pagination, Popconfirm } from "antd";
import { DeleteOutlined, EditTwoTone } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverAddress } from "../../Constant";
import { getToken, getUser } from "../../utils/Auth";
import moment from "moment";
import {
  ErrorNotification,
  SuccessNotification,
} from "../notification/Notification";

const PodcastTable = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState([]);
  const [podcastData, setPodcastData] = useState([]);
  let podcast = useSelector((state) => state.common.podcast);
  const getPodcast = async () => {
    await axios
      .get(`${serverAddress}/podcast/getAllPodcast`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        setLoader(false);
        dispatch({
          type: "SET_PODCAST",
          payload: {
            podcast: res?.data,
          },
        });
        setPodcastData(res);
        setLoader(false);
      })
      .catch((err) => {
        ErrorNotification(err);
      });
  };

  useEffect(() => {
    getPodcast();
  }, []);

  useEffect(() => {
    setSearchValue(podcast);
  }, [podcast]);

  const columns = [
    {
      title: "Podcast Name",
      dataIndex: "Podcast_name",
      key: "Podcast_name",
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (timestamp) => {
        const date = moment
          .unix(timestamp._seconds)
          .milliseconds(timestamp._nanoseconds / 1000000);
        return date.format("YYYY-MM-DD HH:mm ");
      },
    },
    {
      title: "No of Seasons",
      dataIndex: "totalSeasons",
      key: "totalSeasons",
    },
  ];

  const userRole = getUser();

  const handleNewSubUser = () => {
    history.push("/podcastCreate");
  };
  const handlePodcastClick = (podcastId) => {
    history.push({
    pathname: '/season',
    state: podcastId })
  };

  return (
    <>
      <Card
      title={<span style={{ color: '#FF6A3C' , fontWeight: "bold" }}> Podcasts</span>}
        extra={
          userRole?.role !== 3 && (
            <Radio.Button onClick={handleNewSubUser}>
              Add New Podcast
            </Radio.Button>
          )
        }
      >
        <StyledTable
          tableLayout={"unset"}
          pagination={<Pagination defaultCurrent={2} total={podcast?.length} />}
          columns={columns}
          dataSource={podcastData?.data}
          key={podcast?.id}
          loading={loader}
          onRow={(record) => {
            return {
              onClick: () => handlePodcastClick(record.id),
            };
          }}
        />
      </Card>
    </>
  );
};

export default PodcastTable;

const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }

  @media (max-width: 768px) {
    .ant-table-thead > tr > th {
    }
    .ant-table {
      overflow-x: auto;
    }
  }
`;
