import React, { useState } from "react";
import styled from "styled-components";
import { Card, Input, Button, Upload, message, Form } from "antd";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  getToken,
  setEpisodeId,
  getEpisodeId,
  getUser,
} from "../../utils/Auth";
import { UploadOutlined } from "@ant-design/icons";

import { useEffect } from "react";
import {
  ErrorNotification,
  SuccessNotification,
} from "../notification/Notification";
import { serverAddress } from "../../Constant";

const UpdateName = () => {
  const [episodeData, setEpisodeData] = useState({
    // name: "",
    // guest_names: [],
    // file: "",
  });
  const [oldVal, setOldVal] = useState();
  const [thumbnail, setThumbnail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [episodelocALId, setEpisodelocAlId] = useState();
  // const [guest_names, setGuestsName] = useState([]);
  const getEpisode = getEpisodeId();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  

  const AddEpisode = (event) => {
  
    const qs = require("qs");
    axios
      .post(
        `${serverAddress}/episode/editEpisode?episode_id=${getEpisode}`,
        
        event,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          },
        }
      )
      .then((res) => {
        history.push({ pathname: "/newepisode", id: res.data.getEpisode });
        // setEpisodeId(res.data.episode.id);
        setIsLoading(false);
        SuccessNotification("User Updated Successfully");
      })
      .catch((err) => {
        ErrorNotification("Invalid Information please check again");
        console.log(err, "err");
        setIsLoading(false);
      });
  };
  const get = async () => {
   await axios
      .get(`${serverAddress}/episode/getEpisodeById?episode_id=${getEpisode}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        setIsLoading(false);
        console.log("res",res.data)
        form.setFieldsValue({
          episode_name: res.data.episode_name,
          guest_name: res.data.guest_name,
        });

        setOldVal(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (event) => {
    AddEpisode({
      thumbnail_url: thumbnail,
      episode_name: event.episode_name,
      guest_name: event.guest_name,
    });
    setIsLoading(true);
  };
  useEffect(() => {
    getEpisodeId();
    get();
    setIsLoading(true);
  }, []);

  return (
    <Wrapper>
      <Card>
        <Form form={form} onFinish={handleSubmit} initialValues={oldVal}>
          <div className="loginMain">
            <div className="bodyContent">
              <h2>Update Episode</h2>

              <Form.Item name="episode_name" className="inputField">
                <Input
                  // defaultValue={oldVal.episode_name}
                  // placeholder="Episode Name"
                  type="text"
                  // onChange={handleChange}
                />
              </Form.Item>
              <Form.Item name="guest_name" className="inputField">
                <Input
                  id="guest_name"
                  // value={episodeData?.guest_name}
                  placeholder="Guest Name"
                  type="text"
                  // onChange={handleGuestsName}
                />
              </Form.Item>
              <br />
              <Form.Item style={{ display: "flex" }}>
                <Upload
                  id="thumbnail"
                  listType="picture"
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  beforeUpload={(file) => {
                    const imageformData = new FormData();
                    imageformData.append("file", file);

                    axios
                      .post(`${serverAddress}/episode/Upload`, imageformData, {
                        headers: {
                          Authorization: `Bearer ${getToken()}`,
                          "Content-Type": "multipart/form-data",
                        },
                      })
                      .then((response) => {
                        // Handle the response
                        console.log(response);
                        setThumbnail(response.data.signedUrl[0]);
                      })
                      .catch((error) => {
                        // Handle errors
                      });

                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
                </Upload>
              </Form.Item>

              <Form.Item className="bottomSection">
                <Button
                 style={{marginLeft:"95px" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  // disabled={episodeData.file === ""}
                >
                  Update Episode
                </Button>
                <Button
                style={{marginLeft:"95px",marginTop:"10px", background:"#ff6a3c" }}
                  onClick={()=>{
                    history.push({ pathname: "/newepisode", id: getEpisode });
                  }}
                  type="primary"
                  // htmlType="submit"
                  loading={isLoading}
                  // disabled={episodeData.file === ""}
                >
                  Skip
                </Button>
                
              </Form.Item>
            </div>
          </div>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default UpdateName;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  color: white;
  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }

  .bodyContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #000;
      font-weight: 800;
      font-size: 25px;
    }
  }

  .bottomSection {
    width: 40%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    button {
      width: 200px;
      height: 45px;
      align-self: end;
      border: none;
      border-radius: 5px;
      font-weight: bold;
      background: #1a2138;
      justify-content: space-between;
    }
    button:hover {
      background: #ff6a3c;
      border: 1px solid white;
    }
  }
  .inputField {
    width: 40%;
    margin-top: 20px;
  }
  .ant-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: black;
    font-weight: 500;
    transition: all 0.5s ease-out;
  }
  .ant-input:not(.ant-input-affix-wrapper > input.ant-input):focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused:focus {
    border: 1px solid black;
    box-shadow: 0 0 0 1px #1a2138 !important;
    transition: all 0.5s ease-out;
  }
  .ant-input-affix-wrapper > input.ant-input {
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid orange !important;
  }
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-weight: 500;
  }
`;
