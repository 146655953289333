import React from 'react'
import styled from 'styled-components'
import two from '../../assets/images/2.jpg'

function WhatCanEMDo() {
    return (
        <Wrapper>
            <br/>
            <br/>
            <div className="left">
                <label>Label</label>
                <h1>What Can <span>Episode manager</span> Do For <span>You</span> and Your<span> team</span>?</h1>
                <p>EpisodeManager.com allows you to easily keep track of all aspects of your podcast. No more notes, scribbles, forms, papers, stickies, napkins, etc. Our platform will guide you with best practices to plan your awesome episode, record it sounding like a pro, have your editors know exactly where to find that special quote and even give your social media person tons of inspiration to get the word out so more people can hear you.</p>
                <p>In other words, it is what I wish I knew, or someone told me before I started my podcast. Sound familiar?</p>
            </div>
            <div className="right">
                <img src={two} alt="Episode Manager" />
            </div>
        </Wrapper>
    )
}

export default WhatCanEMDo

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    color: #1a2138;
    padding: 20px;
    height: 100%;


    @media (min-width: 768px) {
        flex-direction: row;
        padding: 20px 200px;
    }

    .left, .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;

    }

    .left {
        label {
            color: #999;
        }

        h1 {
            span {
                color: #ff6a3c;
                font-weight: bold;
            }
        }

        p {
            font-size: 15px;
        }
    }

    .right {
        img {
            width: 100%;
            max-width: 400px;
            border-radius: 25px;
        }
    }
`
