import React from 'react';
import styled from 'styled-components';
import image from '../../assets/images/faq.png';

function FAQHead() {
  return (
    <Wrapper>
      <div className='top'>
        <div className='left'>
          <h1>FREQUENTLY ASKED QUESTIONS</h1>
          <p>Want to know more? We’ve got all the answers for you!</p>
        </div>
        <div className="right">
          <img src={image} alt="FAQ" />
        </div>
      </div>
    </Wrapper>
  );
}

export default FAQHead;

const Wrapper = styled.div`
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0px;
    flex-wrap: wrap;

    .left, .right {
      flex: 1;
      max-width: calc(50% - 20px); /* Adjust the width as needed */
    }

    .left {
      text-align: center;
      color: #1a2138;

      h1 {
        font-weight: bold;
        color: #ff6a3c;
      }
    }

    .right {
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 20px; /* Adjust the border radius as needed */
      }
    }
  }

  @media (max-width: 768px) {
    .top {
      flex-direction: column;
      text-align: center;

      .left, .right {
        flex: none;
        max-width: 100%;
      }

      .right {
        margin-top: 20px;
      }
    }
  }
`;
