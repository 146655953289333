import React from "react";
import EpisodeTable from "../../../../components/episodeTable/EpisodeTable";
import { Wrapper } from "./Episodes.styled";

const Episodes = () => {
  return (
    <Wrapper>
      <EpisodeTable />
    </Wrapper>
  );
};

export default Episodes;
