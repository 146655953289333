import React from "react";
import styled from "styled-components";
import { Table, Checkbox, Space } from "antd";

const ShowMomentTable = ({ data }) => {
  const columns = [
    {
      title: "Moment #",
      dataIndex: "momentNo",
    },
    { title: "Moment", dataIndex: "moment" },
    {
      title: "Time Stamp",
      dataIndex: "timeStamp",
    },
    {
      title: "Quote Worth",
      dataIndex: "quoteWorth",
      render: (quoteWorth) => (
        <Space size="middle">
          <Checkbox checked={quoteWorth} />
        </Space>
      ),
    },
    {
      title: "Audiogram Worthy",
      dataIndex: "audiogramWorthy",
      render: (audiogramWorthy) => (
        <Space size="middle">
          <Checkbox checked={audiogramWorthy} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <StyledTable
        pagination={false}
        // rowSelection={{
        //   ...rowSelection,
        // }}
        // expandable={expandable}
        // bordered
        tableLayout={"unset"}
        // title={title}
        // showHeader={showHeader}
        columns={columns}
        dataSource={data}
        // scroll={{ y: 500 }}
      />
    </>
  );
};

export default ShowMomentTable;

const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }
  //   .ant-table-tbody > tr.ant-table-row:hover > td,
  //   .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  //     background: #ff6a3c;
  //   }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }
`;
