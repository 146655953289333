import React from 'react'
import { Wrapper } from './FAQ.styled'
import TopBar from '../../../components/topBar/TopBar'
import FAQCollapse from '../../../components/Faq/FAQCollapse'
import FAQHead from '../../../components/Faq/FAQHead'


function FAQ() {
    return (
        <>
            <TopBar color />
            <Wrapper>
                <FAQHead />
                <FAQCollapse />
                <br/>
                <br/>
            </Wrapper>
        </>
    )
}

export default FAQ