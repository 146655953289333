import React from 'react'
import styled from 'styled-components'
import two from '../../assets/images/2.jpg'

function HassleFree() {
    return (
        <Wrapper>
            <div className="left">
                <label>Quick + Easy</label>
                <h1>Podcast <span>Hassle-Free</span></h1>
                <p>EpisodeManager.com is the only podcaster platform built specifically to help the newbie podcaster, the intermediate podcaster, and the advanced podcaster.</p>
            </div>
            <div className="right">
                <img src={two} alt="Podcast Hassle-Free" />
            </div>
        </Wrapper>
    )
}

export default HassleFree

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    color: #1a2138;
    padding: 20px;
    height: 100%;


    @media (min-width: 768px) {
        flex-direction: row;
        padding: 20px 200px;
    }

    .left, .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .left {
        label {
            color: #999;
        }

        h1 {
            span {
                color: #ff6a3c;
                font-weight: bold;
            }
        }

        p {
            font-size: 15px;
        }
    }

    .right {
        img {
            width: 100%;
            max-width: 400px; /* Adjust max-width as needed */
            border-radius: 25px;
        }
    }
`
