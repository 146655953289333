// export const getUser = () => {
//   const userStr = localStorage.getItem("name");
//   if (userStr) return JSON.parse(userStr);
//   else return null;
// };

// return the token from the session storage
export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
export const getToken = () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accesstoken;
  return token;
};

export const getEpisodeId = () => {
  return localStorage.getItem("episode_id");
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("user");
};

export const removeEpisodeId = () => {
  localStorage.removeItem("episode_id");
};

// set the token and user from the session storage
export const setUserSession = (user) => {
  localStorage.setItem("user", user);
};

export const setEpisodeId = (episode_id) => {
  localStorage.setItem("episode_id", episode_id);
};
export const setPodcastId = (podcast_id) => {
  localStorage.setItem("podcast_id", podcast_id);
};
