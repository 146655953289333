import React from 'react'
import PricingCard from '../../../components/pricing/PricingCard'
import TopBar from '../../../components/topBar/TopBar'
import { Wrapper } from './Pricing.styled'

function Price() {
    return (
        <Wrapper>
            <TopBar color />
            <br/>
            <p style={{ textAlign: "center", color: "#1a2138", fontSize: "15px" }}>You’ve made the decision to launch a podcast. Let our system guide you with best practices for staying organized and manage all the production of your podcast.
                EpisodeManager.com keeps all the important stuff at your fingertips to make podcasting, interviewing, editing and promoting a SNAP!
            </p>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <PricingCard rate={10} />
                <PricingCard rate={20} main />
                <PricingCard rate={30} />
                <br/>
                <br/>
            </div>
        </Wrapper>
    )
}

export default Price
