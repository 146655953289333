import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Table, Space, Radio, Card, Pagination, Popconfirm } from "antd";
import { DeleteOutlined, EditTwoTone } from "@material-ui/icons";
import { serverAddress } from "../../../Constant";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../notification/Notification";
import { getToken, getUser } from "../../../utils/Auth";

const SubUserTable = () => {
  const users = useSelector((state) => state.common.users);
  const SubUsers = useSelector((state) => state.common.SubUsers);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState([]);

  const GetUser = () => {
    setLoader(true);
    axios
      .get(`${serverAddress}/teamMember/getAllTeamMember`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        const arr = res?.data?.data;

        const podcasters = axios.get("/podcaster/getAllPodcaster", {
          headers: { Authorization: `Bearer ${getToken()}` },
        });

        podcasters.then((res) => {
          if (res?.data?.success !== false) {
            for (const value of arr) {
              const podcastUser = res?.data.find(
                (podcaster) => podcaster.id === value.podcast_user_id
              );
              if (podcastUser) {
                value.podcaster = podcastUser;
              }
            }
            setSearchValue(arr);
            setLoader(false);
          } else {
            setSearchValue(arr);
            setLoader(false);
          }
        });
      })
      .catch((err) => {
        console.log("error message==>", err);
        setLoader(false);
      });
  };

  const onDelete = (id, e) => {
    e.preventDefault();
    setLoader(true);
    deleteEpisode(id);
  };

  const deleteEpisode = (userID) => {
    const id = userID;

    axios
      .delete(
        `${serverAddress}/teamMember/deleteTeamMemberByID?user_Id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        GetUser();
        if (res?.data?.success) {
          SuccessNotification("Team member Deleted.");
          setLoader(false);
        } else {
          setLoader(false);
          ErrorNotification("Error deleting Team Member");
        }
      })
      .catch((err) => {
        console.log("error message==>", err.message);
        setLoader(false);
        ErrorNotification("Error deleting episode");
      });
  };

  useEffect(() => {
    GetUser();
  }, []);

  const handleNewSubUser = () => {
    history.push("/addNewTeamMember");
  };

  const userRole = getUser();
let columns = [
  {
    title: "First Name",
    dataIndex: "firstname",
    key: "firstname",
  },
  {
    title: "Last Name",
    dataIndex: "lastname",
    key: "lastname",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

if (userRole.role !== "2") {
  columns.push({
    title: "Podcaster Name",
    render: (text, record) => (
      <Space size="middle">
        {record?.podcaster?.firstname + " " + record?.podcaster?.lastname}
      </Space>
    ),
  });
}

if (userRole.role === "1" || userRole.role === "2") {
  columns.push({
    title: "Action",
    key: "action",
    render: (value, record) => (
      <Space size="middle">
        <a
          onClick={() => {
            history.push({
              pathname: "/editTeamMember",
              id: record?.id,
            });
          }}
        >
          <Space>
            <EditTwoTone />
          </Space>
        </a>
        <Space>
          <Popconfirm
            title="Are you sure to delete this Episode"
            onConfirm={(e) => {
              onDelete(record.id, e);
            }}
            okText="Delete"
            okType="danger"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      </Space>
    ),
  });
}

  
return (
  <>
    <Card
    title={<span style={{ color: '#FF6A3C' , fontWeight: "bold" }}>Team Members</span>}
      extra={
        userRole?.role !== 3 && (
          <Radio.Button onClick={handleNewSubUser}>Add New</Radio.Button>
        )
      }
    >
      <StyledTable
        tableLayout={"unset"}
        pagination={<Pagination defaultCurrent={5} total={users?.length} />}
        columns={columns}
        dataSource={searchValue}
        loading={loader}
      />
    </Card>
  </>
);
};

export default SubUserTable;

// Add CSS media queries for responsiveness
const StyledTable = styled(Table)`
height: 100%;
.ant-table-thead > tr > th {
  background: #1a2138;
  color: white;
}

.ant-table-tbody > tr > td {
  font-size: 14px;
}
.ant-table-title {
  border: none;
  font-size: 20px;
  text-align: center;
}
.ant-table {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
}

.ant-space-item {
  color: #1a2138;
}

@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .ant-table-thead > tr > th {
    /* Modify styles for table headers */
  }
  .ant-table {
  overflow-x: auto;
}

}
`;