import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Input, Row, Col, Button, Spin } from "antd";
import { getEpisodeId } from "../../../utils/Auth";
import axios from "../../../axois/Axois";
import { ErrorNotification, SuccessNotification } from "../../index";
import { getToken } from "../../../utils/Auth";
import { useLocation } from "react-router-dom";
import TextEditor from "../../textEditor/TextEditor";
import { serverAddress } from "../../../Constant";

const { TextArea } = Input;

const EpisodeTopic = ({ tab, setTab }) => {
  const location = useLocation();
  let id = getEpisodeId();
  const [formData, setFormData] = useState({
    episode_id: id,
    topic_to_cover: "",
    topics_to_avoid: [
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
      { topics: "" },
    ],
    other_notes: "",
  });

  const [richData, setRichData] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [isBtnloading, setIsBtnLoading] = useState(false);
  console.log("formData", formData);

  //HANDLE CHANGE

  const handleTopicsToCoverChange = (html) => {
    console.log(html);
    if (html != richData) {
      setRichData(html);
    }
  };

  const handleChangeAvoid = (index, event) => {
    const values = [...formData.topics_to_avoid];
    values[index][event.target.name] = event.target.value;
    setFormData({ ...formData, topics_to_avoid: values });
    console.log(formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    console.log(e.target);
  };

  //API CALLS

  const postApiCall = () => {
    axios
      .post(
        `${serverAddress}/episodeDetails/editEpisodeDetails`,
        { ...formData, topic_to_cover: richData },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        console.log(res);
        setIsBtnLoading(false);
        SuccessNotification("Episode info saved successfully");
        setTab(4);
      })
      .catch((err) => {
        ErrorNotification("Error saving episode info");
        console.log(err);
        setIsBtnLoading(false);
      });
  };

  const getApiCall = () => {
    axios
      .get(
        `${serverAddress}/episodeDetails/getEpisodeDetailsById?episode_id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setFormData({
          ...formData,
          topic_to_cover:
            res.data.topic_to_cover === undefined
              ? formData.topic_to_cover
              : res.data.topic_to_cover,
          topics_to_avoid:
            res.data.topics_to_avoid === undefined
              ? formData.topics_to_avoid
              : res.data.topics_to_avoid,
          other_notes:
            res.data.other_notes === undefined
              ? formData.other_notes
              : res.data.other_notes,
        });
        setRichData(
          res.data.topic_to_cover === undefined
            ? richData
            : res.data.topic_to_cover
        );
      })
      .catch((err) => {
        ErrorNotification("Request Failed");
        setIsLoading(false);
        console.log(err);
      });
  };

  // BUTTONS FUNCTION

  useEffect(() => {
    getApiCall();
  }, []);

  const next = () => {
    postApiCall();
    setIsLoading(true);
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const back = () => {
    setTab(2);
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Wrapper>
      {isloading ? (
        <Spin />
      ) : (
        <>
          <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                <Button onClick={back}>Prev Form</Button>
              </Col>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
                <Button
                  className="submit"
                  onClick={next}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>
          <br />

          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div className="links">
                <Form.Item>
                  <div style={{ whiteSpace: "pre" }}>Topics To Cover:</div>
                  <TextEditor
                    id="topic_to_cover"
                    type="text"
                    rows={8}
                    placeholder="Enter Topics"
                    value={richData}
                    onChange={handleTopicsToCoverChange}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div className="links">
                <Form.Item>
                  <div style={{ whiteSpace: "pre" }}>Topics to Avoid:</div>
                  {formData.topics_to_avoid.map((item, index) => (
                    <Input
                      id="topics_to_avoid"
                      type="text"
                      placeholder="Enter Topics"
                      name="topics"
                      defaultValue={formData?.topics_to_avoid}
                      value={item.topics}
                      onChange={(event) => handleChangeAvoid(index, event)}
                    />
                  ))}
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <div className="links">
                <Form.Item>
                  <div style={{ whiteSpace: "pre" }}>Other Notes:</div>
                  <TextArea
                    rows={10}
                    placeholder="Add Other Notes "
                    onChange={handleChange}
                    id="other_notes"
                    type="text"
                    defaultValue={formData?.other_notes}
                    value={formData?.other_notes}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                <Button onClick={back}>Prev Form</Button>
              </Col>
              <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
                <Button
                  className="submit"
                  onClick={next}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default EpisodeTopic;

const Wrapper = styled.div`
  .links {
    .ant-input {
      margin-bottom: 10px;
    }
  }
  .submit {
    text-align: end;
  }
  .tab-navigate {
    .ant-btn {
      background: #1a2138;
      color: white;
    }
    // display: flex;
  }
`;
