import { Col, Row, Input, Form, Button, Spin } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getEpisodeId } from "../../../utils/Auth";
import { ErrorNotification, SuccessNotification } from "../../index";
import { getToken, getUser } from "../../../utils/Auth";
import { useHistory } from "react-router-dom";
import { useSelector} from "react-redux";
import axios from "../../../axois/Axois"; 
import { useLocation } from "react-router-dom";
import { serverAddress } from "../../../Constant";

const { TextArea } = Input;

const GuestInfo = ({ setTab }) => {
  const episodes = useSelector((state) => state.common.episodes);
  const userRole = getUser()?.role;
  const id = getEpisodeId();
  const location = useLocation();
  const history = useHistory();
  const [formData, setFormData] = useState({
    episode_id: id,
    podcast_Id: episodes.podcast_Id,
    Podcast_name: episodes.Podcast_name,
    guest_Name: "",
    email: "",
    street: "",
    postal: "",
    city: "",
    state: "",
    country: "",
  });
  const [isloading, setIsLoading] = useState(false); 
  const [isBtnloading, setIsBtnLoading] = useState(false);  
  console.log("pod", formData);
  console.log("pod1", setFormData);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setIsBtnLoading(true);
    axios
      .post(`${serverAddress}/guest/add_Guest`, formData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log(res);
        setIsBtnLoading(false);
        SuccessNotification("Guest info saved successfully");
        setTab(2);
      })
      .catch((err) => {
        ErrorNotification("Error saving Guest info");
        console.log(err);
        setIsBtnLoading(false);
      });
  };

  return (
    <Wrapper>
      {isloading ? (
        <Spin />
      ) : (
        <>
          <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={24} xxl={24} className="submit">
                <Button
                  className="submit"
                  loading={isBtnloading}
                  onClick={handleSubmit}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>

          <Row gutter={[15, 15]}>
            <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
              Guest Name:{" "}
              <Input
                id="guest_Name"
                type="text"
                placeholder="Enter Guest Name"
                value={formData.guest_Name}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
              Email:{" "}
              <Input
                id="email"
                type="text"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleChange}
              />
            </Col>

            <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
              Street:
              <Input
                id="street"
                type="text"
                placeholder="Enter Street"
                value={formData.street}
                onChange={handleChange}
              />
            </Col>

            <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
              Postal Code:
              <Input
                id="postal"
                type="text"
                placeholder="Enter Postal Code"
                value={formData.postal}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={8} xxl={8}>
              City:{" "}
              <Input
                id="city"
                type="text"
                placeholder="Enter City"
                value={formData.city}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={8} xxl={8}>
              State:{" "}
              <Input
                id="state"
                type="text"
                placeholder="Enter State"
                value={formData.state}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={8} xxl={8}>
              Country:
              <Input
                id="country"
                type="text"
                placeholder="Enter Country"
                value={formData.country}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <br />
          <br />
          <div className="tab-navigate">
            <Row>
              <Col xs={24} sm={24} lg={24} xl={24} xxl={24} className="submit">
                <Button
                  className="submit"
                  onClick={handleSubmit}
                  loading={isBtnloading}
                >
                  Next Form
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default GuestInfo;

const Wrapper = styled.div`
  .fields {
    align-items: center;
  }
  .ant-input {
    border-radius: 5px;
  }
  .ant-row {
    margin: 10px;
  }

  .links {
    .ant-input {
      margin-bottom: 10px;
    }
  }
  .submit {
    text-align: end;
  }
  .tab-navigate {
    .ant-btn {
      background: #1a2138;
      color: white;
    }
  }
`;
