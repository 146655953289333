import React from "react";
import styled from "styled-components";
import image from "../../assets/images/about/aboutusimg.PNG";
import imageone from "../../assets/images/about/aboutsign.PNG";
import imagetwo from "../../assets/images/about/aboutbackground.PNG";
import aboutone from '../../assets/images/about/aboutone.PNG'
import abouttwo from '../../assets/images/about/abouttwo.PNG'
import aboutthree from '../../assets/images/about/aboutthree.PNG'

function AboutHead() {
  return (
    <Wrapper>
      <div className="aboutusheading">
        <h1 className="aboutusmainheading">About Us</h1>
      </div>
      <div className="aboutusbody">
        <img src={image} alt="background image" />
        <div className="aboutus_content">
          <h1 className="contentheadingone">
            We are a digital agency focused on delivering content and utility
            user-experiences.
          </h1>
          <p className="contentparaone">
            Adipiscing lacus ut elementum, nec duis, tempor litora turpis
            dapibus. Imperdiet cursus odio tortor in elementum. Egestas nunc
            eleifend feugiat lectus laoreet, vel nunc taciti integer cras. Hac
            pede dis, praesent nibh ac dui mauris sit. Pellentesque mi, facilisi
            mauris, elit sociis leo sodales accumsan. Iaculis ac fringilla
            torquent lorem consectetuer, sociosqu phasellus risus urna aliquam,
            ornare.
          </p>
          <img src={imageone} alt="signature" />
          <img src={imagetwo} alt="background" className="backimage" />
          <div className="aboutpicturesdiv">
            <img src={aboutone} alt="aboutone" className="aboutimages" />
            <img src={abouttwo} alt="abouttwo" className="aboutimages" />
            <img src={aboutthree} alt="aboutthree" className="aboutimages" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default AboutHead;

const Wrapper = styled.div`
  .aboutusheading {
    width: 100%;
    background: #d3d3d32e;
    height: 80px;
  }
  
  h1.aboutusmainheading {
    text-align: center;
    font-size: 33px;
    padding-top: 12px;
  }
  
  .aboutusbody {
    padding: 50px 0px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  h1.contentheadingone {
    font-size: 22px;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 21px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  p.contentparaone {
    text-align: center;
    padding: 0px 20px;
  }
  
  img.backimage {
    width: 100%;
  }
  
  .aboutpicturesdiv {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap; /* Add wrapping for smaller screens */
  }
  
  .aboutimages {
    max-width: 100%;
    margin-bottom: 20px;
  }

  /* Media Query for screens smaller than 768px */
  @media (max-width: 768px) {
    h1.aboutusmainheading {
      font-size: 24px;
    }
    p.contentparaone {
      padding: 0px 10px;
    }
  }
`;
