import React from "react";
import { Wrapper } from "./Accounts.styled";
import { Card, Row, Col } from "antd";
import { getToken, getUser } from "../../../../utils/Auth";

const Accounts = () => {
  const { firstname, lastname, email } = getUser().user;
  return (
    <Wrapper>
      <Row>
        <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
          <Card>
            <h2>Name</h2>
            <p>
              {firstname} {lastname}
            </p>
            <br />
            <h2>Email</h2>
            <p>{email}</p>
            <br />
            <h2>Role</h2>
            <p>{getUser().role == "1"? "Admin": getUser().role == "2"? "Podcaster": "Team Member" }</p>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Accounts;
