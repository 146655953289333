import React from 'react'
import { Wrapper } from './Home.styled'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import GetStarted from '../../../components/home/GetStarted'
import HassleFree from '../../../components/home/HassleFree'
import WhatIsEM from '../../../components/home/WhatIsEM'
import WhyEM from '../../../components/home/WhyEM'
import TopBar from '../../../components/topBar/TopBar'
import WhatCanEMDo from '../../../components/home/WhatCanEMDo'
import Feedbacks from '../../../components/home/Feedbacks'
import Footer from '../../../components/home/Footer'


function Home() {
    return (
        <Wrapper>
            <Parallax style={{ height: "100vh" }} page={6}>
                <ParallaxLayer style={{ zIndex: "1", height: "88px", backdropFilter: "brightness(0.4)" }} offset={4} sticky={{ start: 0, end: 6 }}>
                    <TopBar white />
                </ParallaxLayer>
                <ParallaxLayer offset={0} sticky={{ start: 0, end: 0 }}>
                    <GetStarted />
                </ParallaxLayer>
                <ParallaxLayer offset={0.5} sticky={{ start: 1, end: 0 }}>
                    <WhatIsEM />
                </ParallaxLayer>
                <ParallaxLayer offset={2} sticky={{ start: 2, end: 0 }}>
                    <HassleFree />
                </ParallaxLayer>
                <ParallaxLayer offset={3} sticky={{ start: 3, end: 0 }}>
                    <WhyEM />
                </ParallaxLayer>
                <ParallaxLayer offset={4} sticky={{ start: 4, end: 0 }}>
                    <WhatCanEMDo />
                </ParallaxLayer>
                <ParallaxLayer offset={5} sticky={{ start: 5, end: 1 }}>
                    <Feedbacks />
                    <Footer />
                </ParallaxLayer>
            </Parallax>
        </Wrapper >
    )
}

export default Home

