import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import logo from "../../assets/images/EpisodeManager_Logo.png";
import usa from "../../assets/images/flags/united-states.png";

function Footer() {
  const history = useHistory();

  return (
    <Wrapper>
      <div className="top">
        <div className="top-left">
          <img className="logo" src={logo} alt="Episode Manager Logo" />
          <p>Podcast streaming app</p>
          <div>
            <img src={usa} alt="US Flag" />
            <label>EN</label>
          </div>
        </div>
        <div className="top-right">
          <div className="links">
            <ul>
              <li>
                <a>Home</a>
              </li>
              <li>
                <a>About Us</a>
              </li>
              <li>
                <a>FAQ</a>
              </li>
              <li>
                <a>Pricing</a>
              </li>
              <li>
                <a>Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="buttons">
            <div
              className="login"
              
              onClick={() => history.push("/login")}
            >
              Login
            </div>
            <div className="signup" onClick={() => history.push("/signup")}>
              Signup
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div>© Episode Manager. All Rights Reserved.</div>
      </div>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 70px 20px; /* Reduce horizontal padding for mobile view */
  padding-bottom: 25px;
  min-height: 300px; /* Use min-height to ensure the footer always covers the screen height */
  background: #f3f3f3;
  color: #1a2138;

  @media (min-width: 768px) {
    /* Apply styles for screens wider than 768px (tablets and desktops) */
    padding: 70px 150px;
  }

  .top {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      /* Adjust layout for wider screens */
      flex-direction: row;
      justify-content: space-between;
    }

    .top-left {
      .logo {
        width: 160px;
      }
      div {
        display: flex;
        border: 1px solid #1a2138;
        border-radius: 7px;
        justify-content: center;
        width: 60px;
        img {
          width: 20px;
        }
      }
      p {
        margin-top: 10px;
      }
    }
    .top-right {
      display: flex;
      gap: 2rem;
      flex-direction: column;
      width: 100%; /* Take full width on mobile */

      @media (min-width: 768px) {
        /* Adjust width for wider screens */
        flex-direction: row;
        justify-content: space-between;
        width: auto; /* Reset width for wider screens */
      }

      .links {
        ul {
          list-style-type: none;
          margin: 0px;
          padding: 0px;
          li {
            padding-top: 5px;
            a {
              color: #1a2138;
              :hover {
                color: #ff6a3c;
              }
            }
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        top: 10px;
        .login,
        .signup {
            margin-top: 10px;
            margin-bottom: 10px;
          width: 85px;
          height: 35px;
          text-align: center;
          font-weight: bold;
          padding: 6px 18px;
          border-radius: 5px;
        }
        .login {
          outline: 2px solid #1a2138;
          background: #1a2138;
          color: #fff;
          cursor: pointer;
        }
        .login:hover {
          outline: 2px solid #ff6a3c;
          background: #ff6a3c;
        }
        .signup {
          outline: 2px solid #1a2138;
          cursor: pointer;
        }
        .signup:hover {
          outline: 2px solid #ff6a3c;
          color: white;
          background: #ff6a3c;
        }
      }
    }
  }
  .bottom {
    text-align: center;
  }
`;
