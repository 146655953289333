import React from 'react'
import { Wrapper } from './About.styled'
import TopBar from '../../../components/topBar/TopBar'
import AboutHead from '../../../components/About/AboutHead'

function About() {
  return (
    <Wrapper>
      <TopBar color />
      <AboutHead />
    </Wrapper>
  )
}
 
export default About