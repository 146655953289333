import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, Button } from "antd";
import axios from "../../../axois/Axois";
import { ErrorNotification ,SuccessNotification } from "../../index";
import { getEpisodeId, getToken, removeEpisodeId } from "../../../utils/Auth";
import { useLocation, useHistory } from "react-router-dom";
import { serverAddress } from "../../../Constant";

const { TextArea } = Input;

const EpisodePromotion = ({ tab, setTab }) => {
  const location = useLocation();
  let id = getEpisodeId();
  const [formData, setFormData] = useState({
    episode_id: id,
    social_links: [
      { twitter_link: "" },
      { discord_link: "" },
      { instgram_link: "" },
      { tiktok_link: "" },
      { facebook_link: "" },
      { twitch_link: "" },
    ],
    special_promotion_plan: "",
    paid_promotion: "",
    social_content: [
      { web: "" },
    ],
    episode_performance: "",
    rating: "",
    downloads: "",
    episode_notes: "",
    pre_release_approvals: "",
    pre_release_notes: "",
    release_date_time: "",
    encore_date_time: "",
    
  });
  

  const history = useHistory();

  const [richData, setRichData] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [isBtnloading, setIsBtnLoading] = useState(false);
  console.log("formData", formData);

  //HANDLE CHANGE
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    console.log(e.target);
  };

  const handleSocialLinks = (e) => {
    const values = {
      ...formData.social_links,
      [e.target.name]: e.target.value,
    };
    setFormData({ ...formData, social_links: values });
  };

  const handleSocialContent = (index, event) => {
    const values = [...formData?.social_content];
    values[index][event.target.name] = event.target.value;
    setFormData({ ...formData, social_content: values });
  };
  const handleSocialWeb = (index, event) => {
    const values = [...formData?.social_content];
    values[index][event.target.name] = event.target.value;
    setFormData({ ...formData, social_content: values });
  };

  //API CALLS

  const postApiCall = () => {
    axios
      .post(
        `${serverAddress}/episodeDetails/editEpisodeDetails`,
        { ...formData, topics_to_cover: richData },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        console.log(res);
        history.push({ pathname: "/episodes", id: res.data.getEpisode });
        setIsBtnLoading(false);
        SuccessNotification("Episode info saved successfully");
      })
      .catch((err) => {
        ErrorNotification("Error saving episode info");
        console.log(err);
        setIsBtnLoading(false);
      });
  };



  const getApiCall = () => {
    axios
      .get(
        `${serverAddress}/episodeDetails/getEpisodeDetailsById?episode_id=${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setFormData({
          ...formData,
          social_links: res.data.social_links === undefined
          ? formData.social_links
          : res.data.social_links,
          special_promotion_plan: res.data.special_promotion_plan === undefined
          ? formData.special_promotion_plan
          : res.data.special_promotion_plan,
          paid_promotion: res.data.paid_promotion === undefined
          ? formData.paid_promotion
          : res.data.paid_promotion,
          social_content: res.data.social_content === undefined
          ? formData.social_content
          : res.data.social_content,
          episode_performance: res.data.episode_performance === undefined
          ? formData.episode_performance
          : res.data.episode_performance,
          rating: res.data.rating === undefined
          ? formData.rating
          : res.data.rating,
          downloads: res.data.downloads === undefined
          ? formData.downloads
          : res.data.downloads,
          episode_notes: res.data.episode_notes === undefined
          ? formData.episode_notes
          : res.data.episode_notes,
          pre_release_approvals: res.data.pre_release_approvals === undefined
          ? formData.pre_release_approvals
          : res.data.pre_release_approvals,
          pre_release_notes: res.data.pre_release_notes === undefined
          ? formData.pre_release_notes
          : res.data.pre_release_notes,
          release_date_time: res.data.release_date_time === undefined
          ? formData.release_date_time
          : res.data.release_date_time,
          encore_date_time: res.data.encore_date_time === undefined
          ? formData.encore_date_time
          : res.data.encore_date_time,
          
        });
        setRichData(res.data.promotion === undefined
          ? richData
          : res.data.promotion);
      })
      .catch((err) => {
        ErrorNotification("Request Failed");
        setIsLoading(false);
        console.log(err);
      });
  };
  //BUTTON FUNCTIONS

  useEffect(() => {
    getApiCall();
    console.log(formData);
  }, []);

  const handleSubmit = (e) => {
    postApiCall();
  };

  const prev = () => {
    setTab(5);
    document
      .getElementById("new-episode")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Wrapper>
      <div className="tab-navigate">
        <Row>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Button onClick={prev}>Prev Form</Button>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
            <Button className="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
      <br />

      <h2 style={{ fontWeight: "600" }}>Social Media Links</h2>
      <h3 style={{ color: "grey" }}>
        Please include any links where you'd want listeners to find you.
      </h3>
      <Form>
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item>
              Twitter Link:{" "}
              <Input
                placeholder="https://twitter.com/... "
                onChange={handleSocialLinks}
                name="twitter_link"
                value={formData?.social_links.twitter_link}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item>
              Discord Link:{" "}
              <Input
                placeholder="https://discord.com/... "
                onChange={handleSocialLinks}
                name="discord_link"
                value={formData?.social_links.discord_link}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item>
              Instagram Link:{" "}
              <Input
                placeholder="https://instagram.com/... "
                onChange={handleSocialLinks}
                name="instagram_link"
                value={formData?.social_links.instagram_link}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item>
              Tiktok Link:{" "}
              <Input
                placeholder="https://tiktok.com/... "
                onChange={handleSocialLinks}
                name="tiktok_link"
                value={formData?.social_links.tiktok_link}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item>
              Facebook Link:{" "}
              <Input
                placeholder="https://facebook.com/... "
                onChange={handleSocialLinks}
                name="facebook_link"
                value={formData?.social_links.facebook_link}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
            <Form.Item>
              Twitch Link:{" "}
              <Input
                placeholder="https://twitch.com/... "
                onChange={handleSocialLinks}
                name="twitch_link"
                value={formData?.social_links.twitch_link}
              />
            </Form.Item>
          </Col>
        </Row>
       
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Form.Item>
              <div style={{ whiteSpace: "pre" }}>Special Promotion Plan:</div>
              <TextArea
                rows={15}
                placeholder="Enter Promotion Plan "
                id="special_promotion_plan"
                onChange={handleChange}
                type="text"
                value={formData?.special_promotion_plan}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Form.Item>
              <div style={{ whiteSpace: "pre" }}>Added Boost/Paid Promo:</div>
              <TextArea
                rows={8}
                placeholder="Enter Promo "
                id="paid_promotion"
                onChange={handleChange}
                type="text"
                value={formData?.paid_promotion}
              />
            </Form.Item>
          </Col>
        </Row>
              <h3>Episode Performance</h3>
               <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                  <Form.Item>
                    Your Rating:{" "}
                    <Input
                      placeholder="Enter Rating "
                      id="rating"
                      onChange={handleChange}
                      type="text"
                      value={formData?.rating}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                  <Form.Item>
                    Downloads:{" "}
                    <Input
                      placeholder="Enter Downloads "
                      id="downloads"
                      onChange={handleChange}
                      type="text"
                      value={formData?.downloads}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    Notes:{" "}
                    <Input
                      placeholder="Enter Notes "
                      id="episode_notes"
                      onChange={handleChange}
                      type="text"
                      value={formData?.episode_notes}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                  <Form.Item>
                    Schedule Pre-Release Approvels:
                    <Input
                      placeholder="Enter Schedules "
                      id="pre_release_approvals"
                      onChange={handleChange}
                      type="text"
                      value={formData?.pre_release_approvals}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                  <Form.Item>
                    Notes:{" "}
                    <Input
                      placeholder="Enter Notes "
                      id="pre_release_notes"
                      onChange={handleChange}
                      type="text"
                      value={formData?.pre_release_notes}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                  <Form.Item>
                    Release Date/Time:
                    <Input
                      placeholder="Enter Release Date/Time "
                      id="release_date_time"
                      onChange={handleChange}
                      type="date"
                      value={formData?.release_date_time}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
                  <Form.Item>
                    Encore Date/Time:{" "}
                    <Input
                      placeholder="Enter Encore Date/Time "
                      id="encore_date_time"
                      onChange={handleChange}
                      type="date"
                      value={formData?.encore_date_time}
                    />
                  </Form.Item>
                </Col>
              </Row>
        {formData?.social_content.map((item, index) => {
          return (
            <>
              <Row gutter={[15, 15]} key={index}>
                <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    Social Platform:
                    <Input
                      placeholder="Facebook/Instagram/Twitch/etc... "
                      onChange={(event) => handleSocialWeb(index, event)}
                      name="web"
                      type="text"
                      value={item.web}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15, 15]}key={index}>
                <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    <div style={{ whiteSpace: "pre" }}>
                      Social Content Post Text:
                    </div>
                    <TextArea
                      rows={3}
                      placeholder="Enter Content Text"
                      onChange={(event) => handleSocialContent(index, event)}
                      name="content"
                      type="text"
                      value={item.content}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <br />
            </>
          );
        })}
        <br />
        <br />
        <div className="tab-navigate">
          <Row>
            <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
              <Button onClick={prev}>Prev Form</Button>
            </Col>
            <Col xs={24} sm={24} lg={24} xl={12} xxl={12} className="submit">
              <Button className="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Wrapper>
  );
};

export default EpisodePromotion;

const Wrapper = styled.div`
  .submit {
    text-align: end;
  }
  .tab-container{
    border: 2px solid #1A2138;
    padding: 10px;
    background-color: white;
  }
  .tab-navigate {
    .ant-btn {
      background: #1a2138;
      color: white;
    }
  }
`;




