import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Table,  Card, Pagination} from "antd";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverAddress } from "../../Constant";
import { getToken, getUser } from "../../utils/Auth";
import {
  ErrorNotification,
  SuccessNotification,
} from "../notification/Notification";

const GuestPopup = ({ guestData, closePopup }) => {
  const popupContentStyle = {
    background: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
  };

  return (
    <div style={popupStyle}>
      <div style={popupContentStyle}>
        <h2>Guest Details</h2>
        <p>Podcast Name: {guestData.podcast_name}</p>
        <p>Guest Name: {guestData.guest_Name}</p>
        <button onClick={closePopup}>Close</button>
      </div>
    </div>
  );
};
const popupStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const Guest = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);

  const getGuest = async () => {
    await axios
      .get(`${serverAddress}/guest/get_all_guests`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        setLoader(false);
        dispatch({
          type: "SET_EPISODE",
          payload: {
            episodes: res?.data?.guests,
          },
        });
        setGuestData(res?.data?.guests);
        setLoader(false);
      })
      .catch((err) => {
        ErrorNotification(err);
      });
  };

  useEffect(() => {
    getGuest();
  }, []);
  const openPopup = (guest) => {
    setSelectedGuest(guest);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };
  console.log("vvv", guestData);
  console.log("cccc", setGuestData);
  useEffect(() => {
    setSearchValue();
  }, []);

  const columns = [
    {
      title: "Guest Name",
      dataIndex: "guest_Name",
      key: "guest_Name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Street",
      dataIndex: "street",
      key: "street",
    },
    {
      title: "Postal",
      dataIndex: "postal",
      key: "postal",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
  ];

  const userRole = getUser();

  return (
    <>
      <Card
        title={
          <span style={{ color: "#FF6A3C", fontWeight: "bold" }}> Guests</span>
        }
      >
        <StyledTable
          tableLayout={"unset"}
          pagination={
            <Pagination defaultCurrent={2} total={guestData.length} />
          }
          columns={columns}
          dataSource={guestData}
          loading={loader}
          onRow={(record, rowIndex) => ({
            onClick: () => openPopup(record),
          })}
        />
      </Card>

      {isPopupVisible && (
        <div className="popup">
          <div className="popup-content">
            <GuestPopup guestData={selectedGuest} closePopup={closePopup} />
          </div>
        </div>
      )}
    </>
  );
};

export default Guest;

const StyledTable = styled(Table)`
  height: 100%;
  .ant-table-thead > tr > th {
    background: #1a2138;
    color: white;
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
  }
  .ant-table-title {
    border: none;
    font-size: 20px;
    text-align: center;
  }
  .ant-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }

  .ant-space-item {
    color: #1a2138;
  }

  @media (max-width: 768px) {
    .ant-table-thead > tr > th {
    }
    .ant-table {
      overflow-x: auto;
    }
  }
`;
