import React, { useState } from "react";
import { Wrapper } from "./TopHeader.styled";
import { Input, Drawer, Button, Dropdown, Menu, Modal } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import profilepicture from "../../../assets/images/user.png";
import hamBurgerIcon from "../../../assets/icons/hamBurger.svg";
import { getUser } from "../../../utils/Auth";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar/SidebarPage";
import { Popover } from "antd";
import { useHistory } from "react-router-dom";
import { NotificationsNone } from "@material-ui/icons";
import styled from "styled-components";
import "./TopHeader.css";

const NewPodcastButton = styled(Button)`
  background: #1a2138;
  color: #fff;
  width: auto;
  margin-right: 5px;
  border-radius: 5px;
  &:hover {
    background: #1a2138;
  }
`;

const NewSeasonButton = styled(Button)`
  background: #1a2138;
  color: #fff;
  width: auto;
  margin-right: 5px;
  border-radius: 5px;
  &:hover {
    background: #fff;
  }
`;

const Heading = styled.h4`
  color: #ff6a3c;
  padding: 5px;
  font-style: bold;
  margin-bottom: 5px;
`;

export default function IndexPage() {
  const dispatch = useDispatch();
  const isCollapse = useSelector((state) => state.common.isCollapse);
  const { firstname, lastname } = getUser().user;
  const history = useHistory();
  const userRole = getUser();
  const [visible, setVisible] = useState(false);
  const [clickedPodcast, setClickedPodcast] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState("");
  const [seasons, setSeasons] = useState({}); // State to store the selected seasons
  const podcastId = "";



  const handleSeasonSelection = () => {
    if (!seasons[selectedPodcast]) {
      seasons[selectedPodcast] = [];
    }

    // Calculate the next season number for the selected podcast
    const nextSeason = seasons[selectedPodcast].length + 1;

    seasons[selectedPodcast].push(nextSeason); // Add the season to the selected podcast
    setSeasons({ ...seasons });
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const settingContent = (
    <div className="settingDetails">
      <span onClick={(e) => history.push("/viewer-profile")}>Profile</span>
      <span>Setting</span>
      <span className="logout">Logout</span>
    </div>
  );

 

  return (
    <Wrapper>
      <div className="topBar">
        <div className="leftSide">
          <div className={isCollapse ? "hamBurgerShow" : "hamBurgerHide"}>
            <img
              src={hamBurgerIcon}
              onClick={() => {
                dispatch({
                  type: "SET_COLLAPSE",
                  payload: {
                    isCollapse: !isCollapse,
                  },
                });
              }}
            />
          </div>

          <div className="sideDrawerIcon">
            <img src={hamBurgerIcon} onClick={showDrawer} />
          </div>

          <div className="title">
            <span className="titleFixed"> {">"}</span>{" "}
            {history.location.pathname === "/userdashboard"
              ? "Dashboard"
              : history.location.pathname === "/episodes"
              ? "Episodes"
              : history.location.pathname === "/podcaster"
              ? "Podcaster"
              : history.location.pathname === "/teammembers"
              ? "Team Members"
              : history.location.pathname === "/podcast"
              ? "Podcasts"
              : history.location.pathname === "/season"
              ? "Seasons"
              : history.location.pathname === "/guest"
              ? "Guests"
              : history.location.pathname === "/account"
              ? "Account"
              : ""}
          </div>

          <Drawer
            className="drawer"
            title="Basic Drawer"
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
            width="40%"
          >
            <SideBar />
          </Drawer>
        </div>

        <div className="rightSide">
          {userRole?.role == 1 || userRole?.role == 2 || userRole?.role == 3 ? (
            <div>
              <NewPodcastButton
                className="h-btn"
                onClick={() => history.push("/podcastCreate")}
              >
                New Podcast
              </NewPodcastButton>

              <NewPodcastButton
                className="h-btn"
                onClick={() => history.push("/seasonCreate")}
              >
                New Seoson
              </NewPodcastButton>
              <NewPodcastButton
                className="h-btn"
                onClick={() => history.push("/episodeCreate")}
              >
                New Episode
              </NewPodcastButton>
            </div>
          ) : null}

          <div className="notification">
            <NotificationsNone />
          </div>

          <div className="admin-picture">
            <img src={profilepicture} alt="" />
          </div>

          <div className="admin-name">
            {firstname} {lastname}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
