import React, { useState } from "react";
import { Wrapper } from "./Login.styled";
import { Input, Button, Form } from "antd";
import { useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";
import { serverAddress } from "../../../Constant";
import TopBar from "../../../components/topBar/TopBar";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUserSession } from "../../../utils/Auth";
import { ErrorNotification } from "../../../components";

export default function IndexPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  console.log(userData);
  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.id]: e.target.value,
    });
    setErrorMsg(null);
  };
  const Login = async () => {
    const qs = require("qs");

    await axios
      .post(`${serverAddress}/login/login`, userData)
      // .post(`/login/login`, userData)
      .then((res) => {
        console.log("success message=>", res.data);
        setUserSession(JSON.stringify(res.data));
        history.push("/userdashboard");
        setLoading(false);
      })
      .catch((err) => {
        console.log("error message==>", err.response.data);
        ErrorNotification(err.response.data);
        setLoading(false);
        if (err.response.status === 401) {
          setErrorMsg("Invalid Email or Password");
          setLoading(false);
        }
      });
  };
  const handleLogin = () => {
    setLoading(true);
    Login();
  };
  const formComplete = !!userData.password && !!userData.email;
  return (
    <Wrapper>
      <TopBar color />
      <div className="loginMain">
        <div className="bodyContent">
          <div className="formContainer">
            <h2>Welcome!</h2>
            <p className="signupButton">
              Don't have an account?{" "}
              <span
                style={{
                  color: "#ff6a3c",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/signup")}
              >
                Sign Up
              </span>
            </p>
            {errorMsg && (
              <>
                <small style={{ color: "red", fontSize: "15px" }}>
                  {errorMsg}
                </small>
                <br />
              </>
            )}
            <Form style={{ display: "contents" }}>
              <div className="inputField">
                <Form.Item
                  style={{ margin: "0px" }}
                  id="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your Email!",
                    },
                  ]}
                  type="text"
                  onChange={handleChange}
                  name="email"
                >
                  <Input placeholder="Enter Email address" />
                </Form.Item>
              </div>

              <div className="inputField">
                <Form.Item
                  onChange={handleChange}
                  id="password"
                  name="password"
                  style={{ margin: "0px" }}
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  type="text"
                >
                  <Input.Password
                    placeholder="Enter Password"
                    autoComplete="off"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>

              <div className="bottomSection">
                <p className="forgotPassword">Forgot password?</p>
                <Button
                  type="primary"
                  onClick={handleLogin}
                  loading={loading}
                  disabled={!formComplete}
                >
                  Login
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
