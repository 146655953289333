import React from "react";
import styled from "styled-components";
import { getUser } from "../../../utils/Auth";

const FormTabs = ({ tab, setTab }) => {
  const userRole = getUser()?.role;
  return (
    <Wrapper>
      <div className="main-container">
        <div className="tabs-container">
          <ul>
            <>
              <li className={tab === 1 ? "active" : ""}>Guest Info</li>
              <li className={tab === 2 ? "active" : ""}>Episode Info</li>
              <li className={tab === 3 ? "active" : ""}>Episode Topic</li>
              <li className={tab === 4 ? "active" : ""}>Episode Assets</li>
              <li className={tab === 5 ? "active" : ""}>Special Moments</li>
              <li className={tab === 6 ? "active" : ""}>Episode Promotion</li>
            </>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default FormTabs;

const Wrapper = styled.div`
  .main-container {
    padding: 10px 0px 0px 0px;
    // border-bottom: 1px solid #707070;
    .tabs-container {
      padding: 0px 40px 0px 20px;
      color: white;
      font-size: 12px;
      width: 100%;
      ul {
        background: #1a2138;
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
      }
      li {
        display: inline;
        padding: 8px 30px;
        /* cursor: pointer; */
      }
      .active {
        background: #ff6a3c;
        color: white;
      }
    }
  }
  @media (max-width: 1076px) {
    .main-container {
      width: 100%;
      overflow-x: auto;
      ::-webkit-scrollbar {
        height: 7px;
      }
    }
    .tabs-container {
      width: 738px;
      overflow-x: auto;
    }
  }
  @media (max-width: 1377px) and (min-width: 1200px) {
    .main-container {
      width: 100%;
      overflow-x: auto;
      ::-webkit-scrollbar {
        height: 7px;
      }
    }
    .tabs-container {
      width: 738px;
      overflow-x: auto;
    }
  }
`;

// for condiiton
{
  /* <ul>
  {userRole == 2 || userRole == 3 ? (
    <>
      <li className={tab === 1 ? "active" : ""}>Episode Info</li>
      <li className={tab === 2 ? "active" : ""}>Episode Topic</li>
      <li className={tab === 3 ? "active" : ""}>Episode Assets</li>
      <li className={tab === 4 ? "active" : ""}>Special Moments</li>
      <li className={tab === 5 ? "active" : ""}>Episode Promotion</li>
    </>
  ) : (
    <>
      <li className={tab === 1 ? "active" : ""}>Episode Info</li>
      <li className={tab === 3 ? "active" : ""}>Episode Assets</li>
    </>
  )}
</ul>; */
}
