import React from "react";
import {
  Table,
  Space,
  Radio,
  Card,
  Pagination,
  Select,
  Input,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import image from "../../assets/images/img_forest.jpg";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import EpisodeTable from "../../components/episodeTable/EpisodeTable";

const DashboardList = () => {
  let episodes = useSelector((state) => state.common.episodes);
  // const breakPoints = [
  //   { width: 1496, itemsToShow: 8 },
  //   { width: 1, itemsToShow: 1 },
  //   { width: 621, itemsToShow: 2 },
  //   { width: 850, itemsToShow: 3 },
  //   { width: 1080, itemsToShow: 4 },
  // ];


 

  
  return (
    // <Wrapper>
    //   <Carousel breakPoints={breakPoints}>
    //     {episodes?.map((item, index) => {
    //       return (
    //         <Card key={index}>
    //           <img
    //             className="image"
    //             src={item.thumbnail_url}
    //             // style={{ objectFit: "cover" }}
    //           />

    //           <div className="text" key={episodes?.id}>
    //             <h2>{item?.episode_name}</h2>
    //             <h4>{item?.guest_name[0]}</h4>
    //             <h4>
    //               {new Date(item?.created_at._seconds * 1000).toDateString()}
    //             </h4>
    //           </div>
    //         </Card>
    //       );
    //     })}
    //   </Carousel>
      
    // </Wrapper>
    <>
    <EpisodeTable />
    </>
  );
};

export default DashboardList;

// const Wrapper = styled.div`
//   .image {
//     filter: blur(8px);
//     -webkit-filter: blur(8px);
//     background-image: url(${image});
//     width: 200px;
//     height: 150px;
//     background-size: cover;
//   }
//   .text {
//     background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
//     font-weight: bold;
//     // border: 3px solid #f1f1f1;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 2;
//     width: 80%;
//     padding: 20px;
//     text-align: center;
//     h2 {
//       color: white !important;
//     }
//     h4 {
//       color: white !important;
//     }
//   }
//   .ant-card-body {
//     background: #1a2138;
//   }
// `;

