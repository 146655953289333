import React from "react";
import styled from "styled-components";
import image from "../../assets/images/contact/contactimage.png";

function ContactBody() {
  return (
    <Wrapper>
      <div className="container">
        <div className="content">
          <div className="bannerheading">
            <h2 className="customheadingbanner">
              Get in touch with Contact Us
            </h2>
            <p className="customparabanner">
              Ama is here to help. Discover our office locations and different
              ways to contact us so that we can provide
              <br /> you with the support you need.
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="contactbody">
        <h3 className="contactbodyheadingone">Let's start the conversation</h3>
        <p className="contactbodyparaone">
          Please email us; we'll be happy to assist you.
        </p>
        <div className="box_info">
          <div className="row contact_fill">
            <div className="col-lg-4 form-group">
              <h6 className="headinganother">Full Name</h6>
              <input
                type="text"
                className="form-control"
                name="name"
                id="name"
                placeholder="Enter your name here"
              />
            </div>
            <div className="col-lg-4 form-group">
              <h6 className="headinganother">Email</h6>
              <input
                type="text"
                className="form-control"
                name="email"
                id="email"
                placeholder="info@Foram.com"
              />
            </div>
            <div className="col-lg-4 form-group">
              <h6 className="headinganother">Phone no</h6>
              <input
                type="text"
                className="form-control"
                name="phone"
                id="phone"
                placeholder="+92"
              />
            </div>
          </div>
          <div className="col-lg-12 form-group">
            <h6 className="headinganother">Message</h6>
            <textarea
              className="form-control message"
              id="message"
              placeholder="Enter Your Text ..."
            ></textarea>
          </div>
          <button type="submit" className="btn action_btn thm_btn">
            Send Message
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

export default ContactBody;

const Wrapper = styled.div`
  img {
    margin: 0px 30px;
    max-width: 100%;
    height: auto;
  }
  .container {
    position: relative;
    text-align: center;
  }
  .content {
    background-image: url(${image});
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px; /* Add some padding to keep text within the container */
  }
  .contact_fill {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  textarea#message {
    height: 150px;
    width: 100%;
    padding: 10px 20px;
    background: #f9fbfc;
    border-color: #d9e1e3;
  }
  button.btn.action_btn.thm_btn {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    box-shadow: 0 20px 30px 0 rgba(17, 166, 131, 0.24);
    border-radius: 4px;
    background: #078669;
    border-color: #078669;
    display: inline-block;
    padding: 14px 28px;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border: none;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  input.form-control {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background: #f9fbfc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  h6.headinganother {
    font-size: 16px;
    font-weight: 600;
  }
  h2.customheadingbanner {
    font-size: 38px;
    font-weight: bold;
    color: white;
    margin-bottom: 3px;
  }
  p.customparabanner {
    color: #c3c3c3;
    text-align: center;
    font-size: 18px;
  }
  h3.contactbodyheadingone {
    font-weight: 600;
  }
  .contactbody {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
  }
  p.contactbodyparaone {
    font-size: 16px;
    font-weight: 100;
  }

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .content {
      padding: 0 20px;
    }
    h2.customheadingbanner {
      font-size: 32px;
    }
    p.customparabanner {
      font-size: 16px;
    }
    .contactbody {
      margin: 20px;
      font-size: 20px;
    }
    p.contactbodyparaone {
      font-size: 14px;
    }
  }
`;
