import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "../../axois/Axois";
import { getToken } from "../../utils/Auth";
import { ErrorNotification } from "../index";
import attachment from "../../assets/images/attachment.png";

const EpisodeAssets = ({ episodeData }) => {
  let regex = /\.(jpg|png)$/;

  return (
    <Wrapper>
      <h3 className="heading">Episode Assets</h3>
      <p className="field">HeadShots</p>
      <div className="image">
        {episodeData?.headshotImg1?.["1"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.headshotImg1);
            }}
          >
            {episodeData?.headshotImg1 ? (
              <img src={episodeData?.headshotImg1} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No HeadShots Uploaded</p>
        )}

        {episodeData?.headshotImg2?.["2"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.headshotImg2);
            }}
          >
            {episodeData?.headshotImg2?.["2"] ? (
              <img src={episodeData?.headshotImg2} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No HeadShots Uploaded</p>
        )}

        {episodeData?.headshotImg3?.["3"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.headshotImg3);
            }}
          >
            {episodeData?.headshotImg3 ? (
              <img src={episodeData?.headshotImg3} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No HeadShots Uploaded</p>
        )}

        <p className="field">Bio Document</p>
        {episodeData?.bioImage1?.["1"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.bioImage1);
            }}
          >
            {episodeData?.bioImage1 ? (
              <img src={episodeData?.bioImage1} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No Bio Document Uploaded</p>
        )}
        <br />
        {episodeData?.bioImage2?.["2"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.bioImage2);
            }}
          >
            {episodeData?.bioImage2 ? (
              <img src={episodeData?.bioImage2} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No Bio Document Uploaded</p>
        )}

        <p className="field">Speaker Sheet</p>
        {episodeData?.speakerImage1?.["1"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.speakerImage1);
            }}
          >
            {episodeData?.speakerImage1 ? (
              <img src={episodeData?.speakerImage1} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No Speaker Sheet Uploaded</p>
        )}
        <br />
        {episodeData?.speakerImage2?.["2"] ? (
          <a
            onClick={() => {
              window.open(episodeData?.speakerImage2);
            }}
          >
            {episodeData?.speakerImage2 ? (
              <img src={episodeData?.speakerImage2} />
            ) : (
              <img src={attachment} style={{ background: "white" }} />
            )}
          </a>
        ) : (
          <p className="data-text">No Speaker Sheet Uploaded</p>
        )}
      </div>
    </Wrapper>
  );
};

export default EpisodeAssets;

const Wrapper = styled.div`
  padding: 10px 10px;
  // background: #e7dada;
  .heading {
    border-bottom: 1px solid rgba(53, 86, 129, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-family: "Ultra", sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    text-shadow: 0 2px white, 0 3px #777;
  }
  .field {
    // margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    font-family: "Orienta", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    // text-decoration: underline;
  }

  .flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, 100px);
  }
  .data-text {
    all: initial;
    padding: 0px 10px;
    white-space: break-spaces;
  }
  .image img {
    height: 100px;
    margin: 5px;
    width: 100px;
    border: 2px solid black;
    border-radius: 8px;
  }
`;
