import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Form, Select, notification } from "antd";
import "react-phone-input-2/lib/style.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import axios from "../../axois/Axois";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification, SuccessNotification } from "../../components";
import { serverAddress } from "../../Constant";
import { useHistory } from "react-router-dom";
import { getToken, getUser } from "../../utils/Auth";
export default function AddUser() {
  const [userData, setUserData] = useState({});
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  let podcaster = useSelector((state) => state.common.podcaster);
  const [loader, setloader] = useState(true);
  const dispatch = useDispatch();

  const AddUser = async (event) => {
    const qs = require("qs");
    await axios
      .post(
        `${serverAddress}/teamMember/addNewTeamMember`,
        { ...event },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((res) => {
        setLoading(false);
        history.replace("/teamMembers");
        SuccessNotification("User created successfully");
        
      })
      .catch((err) => {
        console.log("error message==>", err.response.data);
        ErrorNotification(err.response.data.err);
        setLoading(false);
      });
  };

  const GetPodcaster = () => {
    axios
      .get("/podcaster/getAllPodcaster", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log("res", res);
        dispatch({
          type: "PODCASTER_USERS",
          payload: {
            podcaster: res?.data,
          },
        });
        setloader(true);
      })
      .catch((err) => {
        console.log("error message==>", err);
      });
  };

  useEffect(() => {
    GetPodcaster();
    setloader(false);
  }, []);

  const handleSubmit = (event) => {
    // event.preventDefault();
    setLoading(true);
    AddUser(event);
    console.log(event,"event")
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.id]: e.target.value,
    });
    setErrorMsg(null);
  };

  const history = useHistory();
  const formComplete =
    !!userData.firstname &&
    !!userData.lastname &&
    !!userData.email &&
    !!userData.password;
  const userRole = getUser();
  return (
    <Wrapper>
      <div className="loginMain">
        <div className="bodyContent">
          <h2>Add New</h2>
          <Form style={{ display: "contents" }} onFinish={handleSubmit}>
            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  { required: true, message: "Please input your First Name!" },
                ]}
                type="text"
                onChange={handleChange}
                id="firstname"
                name="firstname"
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </div>

            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                ]}
                type="text"
                onChange={handleChange}
                id="lastname"
                name="lastname"
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </div>

            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Email!",
                  },
                ]}
                type="text"
                onChange={handleChange}
                id="email"
                name="email"
              >
                <Input placeholder="Enter Email address" />
              </Form.Item>
            </div>

            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                type="text"
                onChange={handleChange}
                id="password"
                name="password"
              >
                <Input.Password
                  autoComplete="off"
                  placeholder="Enter Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </div>

            <div className="inputField">
              {/* Conditional rendering based on user's role */}
              {userRole?.role == 1 && (
                <Form.Item
                name="podcast_user_id"
                  style={{ margin: "0px" }}
                  rules={[
                    { required: true, message: "Please select a podcaster!" },
                  ]}
                >
                  {/* Select component */}
                  <Select
                    className="podcast-select"
                    placeholder="Select a podcaster"
                    // value={podcaster}
                    onChange={handleChange}
                    loading={loading}
                    // options={podcaster?.firstname}
                  >
                    {/* Mapping over podcaster options */}
                    {podcaster.map((podcaster) => (
                      <Select.Option key={podcaster?.id} value={podcaster?.id}>
                        {podcaster?.firstname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>

            <div className="bottomSection">
              <Button
                style={{}}
                type="primary"
                htmlType="submit"
                // onClick={handleSubmit}
                disabled={!formComplete}
                loading={loading}
              >
                Add User
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  color: white;
  .loginMain {
    padding: 20px 30px 20px 30px;
    height: 100%;
  }

  .bodyContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #000;
      font-weight: 800;
      font-size: 25px;
    }
  }

  .bottomSection {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    button {
      width: 200px;
      height: 45px;
      align-self: end;
      color: white;
      border: none;
      border-radius: 5px;
      font-weight: bold;
      background: #1a2138;
    }
    button:hover {
      background: #ff6a3c;
      border: 1px solid white;
    }
  }
  .inputField {
    width: 40%;
    margin-top: 20px;
  }
  .ant-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: black;
    font-weight: 500;
    transition: all 0.5s ease-out;
  }
  .ant-input:not(.ant-input-affix-wrapper > input.ant-input):focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused:focus {
    border: 1px solid black;
    box-shadow: 0 0 0 1px #1a2138 !important;
    transition: all 0.5s ease-out;
  }
  .ant-input-affix-wrapper > input.ant-input {
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid orange !important;
  }
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-weight: 500;
  }
  .podcast-select {
    width: 100%;
    .ant-select-selector {
      color: black;
      border: 1px solid #c4c4c4;
      font-weight: 500;
      transition: all 0.5s ease-out;
      border-radius: 5px;
      height: 45px;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    input {
      height: 45px !important;
      padding: 10px !important;
    }
  }
`;
