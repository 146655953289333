import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "../../axois/Axois";
import { getToken } from "../../utils/Auth";
import { ErrorNotification } from "../index";

const EpisodePromotion = ({ episodeData }) => {
  return (
    <Wrapper>
      <h3 className="heading">Episode Promotion</h3>

      <p className="field">Social Links</p>
      <div className="flex">
        <div>
          <p className="data-text">
            <strong>Twitter:{episodeData?.social_links?.twitter_link}</strong>
          </p>
        </div>
        <div>
          <p className="data-text">
            <strong>Discord:</strong>
            {episodeData?.social_links?.discord_link}
          </p>
        </div>
        <div>
          <p className="data-text">
            <strong>Facebook:</strong>
            {episodeData?.social_links?.facebook_link}
          </p>
        </div>
        <div>
          <p className="data-text">
            <strong>Twitch:</strong>
            {episodeData?.social_links?.twitch_link}
          </p>
        </div>
        <div>
          <p className="data-text">
            <strong>Tiktok:</strong>
            {episodeData?.social_links?.tiktok_link}
          </p>
        </div>
        <div>
          <p className="data-text">
            <strong>Intsagram:</strong>
            {episodeData?.social_links?.instagram_link}
          </p>
        </div>
      </div>
      <p className="field">Special Promotion:</p>
      <p className="data-text">{episodeData?.special_promotion_plan}</p>
      <p className="field">Paid Promotion:</p>
      <p className="data-text">{episodeData?.paid_promotion}</p>
      <p className="field">Social Content</p>
      {episodeData?.social_content?.map(({ web, content }, index) => {
        return (
          <>
            <div className="flex">
              <div>
                <p key={index} className="data-text">
                  <strong>Name:</strong>
                  {web}
                </p>
              </div>
              <div>
                <p key={index} className="data-text">
                  <strong>Content:</strong>
                  {content}
                </p>
              </div>
            </div>
          </>
        );
      })}
      <div className="flex">
        <div>
          <p className="field">
            Episode Rating:
            <p className="data-text">{episodeData?.rating}</p>
          </p>
        </div>
        <div>
          <p className="field">
            Downloads:<p className="data-text">{episodeData?.downloads}</p>
          </p>
        </div>
      </div>
      <p className="field">Episode Notes:</p>
      <p className="data-text">{episodeData?.episode_notes}</p>
      <p className="field">Pre Release Approvals:</p>
      <p className="data-text">{episodeData?.pre_release_approvals}</p>
      <p className="field">Pre Release Notes:</p>
      <p className="data-text">{episodeData?.pre_release_notes}</p>
      <div className="flex">
        <div>
          <p className="field">
            Release Data/Time:
            <p className="data-text">{episodeData?.release_date_time}</p>
          </p>
        </div>
        <div>
          <p className="field">
            Encore Date/Time:
            <p className="data-text">{episodeData?.encore_date_time}</p>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default EpisodePromotion;

const Wrapper = styled.div`
  padding: 10px 10px;
  // background: #e7dada;
  .heading {
    border-bottom: 1px solid rgba(53, 86, 129, 0.3);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-family: "Ultra", sans-serif;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    text-shadow: 0 2px white, 0 3px #777;
  }
  .field {
    // margin: 1em 0 0.5em 0;
    color: #343434;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    font-family: "Orienta", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
  }

  .flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, 100px);
  }
  .data-text {
    all: initial;
    padding: 0px 10px;
    white-space: break-spaces;
  }
`;
