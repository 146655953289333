import React from "react";
import { Wrapper } from "./SidebarPage.styled";
import logoIcon from "../../../assets/images/icon.png";
import logo from "../../../assets/images/EpisodeManager_White.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dashboard, Voicemail, ExitToApp, Group } from "@material-ui/icons";
import PersonIcon from "@material-ui/icons/Person";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PermCameraMicIcon from "@material-ui/icons/PermCameraMic";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { removeUserSession, getToken, getUser } from "../../../utils/Auth";
export default function IndexPage() {
  const dispatch = useDispatch();
  const isCollapse = useSelector((state) => state.common.isCollapse);
  const history = useHistory();
  const userRole = getUser();
  console.log(isCollapse);

  return (
    <Wrapper
      style={{
        width: isCollapse ? "230px" : "80px",
      }}
    >
      <div className={isCollapse ? "logo" : "hamburger"}>
        {isCollapse ? (
          <img src={logo} onClick={() => history.push("/userdashboard")} />
        ) : (
          <img
            src={logoIcon}
            onClick={() => {
              dispatch({
                type: "SET_COLLAPSE",
                payload: {
                  isCollapse: !isCollapse,
                },
              });
            }}
          />
        )}{" "}
      </div>

      <div className="menu-container">
        <div className="menu">
          <div
            onClick={() => {
              history.push("/userdashboard");
            }}
            className={
              isCollapse
                ? `${
                    history.location.pathname == "/userdashboard"
                      ? "sidebar-active sidebaropen"
                      : "sidebaropen"
                  }`
                : `${
                    history.location.pathname == "/userdashboard"
                      ? "sidebar-active sidebarclose"
                      : "sidebarclose"
                  }`
            }
          >
            <Dashboard sx={{ fontSize: "20px" }} />

            <p>Dashboard</p>
          </div>

          <div
            onClick={() => {
              history.push("/teammembers");
            }}
            className={
              isCollapse
                ? `${
                    history.location.pathname == "/teammembers"
                      ? "sidebar-active sidebaropen"
                      : "sidebaropen"
                  }`
                : `${
                    history.location.pathname == "/teammembers"
                      ? "sidebar-active sidebarclose"
                      : "sidebarclose"
                  }`
            }
          >
            <Group sx={{ fontSize: "20px" }} />

            <p>Team Members</p>
          </div>

          <div
            onClick={() => {
              history.push("/episodes");
            }}
            className={
              isCollapse
                ? `${
                    history.location.pathname == "/episodes"
                      ? "sidebar-active sidebaropen"
                      : "sidebaropen"
                  }`
                : `${
                    history.location.pathname == "/episodes"
                      ? "sidebar-active sidebarclose"
                      : "sidebarclose"
                  }`
            }
          >
            <Voicemail sx={{ fontSize: "20px" }} />
            <p>Episodes</p>
          </div>

          {userRole?.role == 1 && (
            <div
              onClick={() => {
                history.push("/podcaster");
              }}
              className={
                isCollapse
                  ? `${
                      history.location.pathname == "/podcaster"
                        ? "sidebar-active sidebaropen"
                        : "sidebaropen"
                    }`
                  : `${
                      history.location.pathname == "/podcaster"
                        ? "sidebar-active sidebarclose"
                        : "sidebarclose"
                    }`
              }
            >
              <GroupWorkIcon sx={{ fontSize: "20px" }} />

              <p>Podcasters</p>
            </div>
          )}

          {userRole?.role == 1 || userRole?.role == 2 ? (
            <div
              onClick={() => {
                history.push("/podcast");
              }}
              className={
                isCollapse
                  ? `${
                      history.location.pathname == "/podcast"
                        ? "sidebar-active sidebaropen"
                        : "sidebaropen"
                    }`
                  : `${
                      history.location.pathname == "/podcast"
                        ? "sidebar-active sidebarclose"
                        : "sidebarclose"
                    }`
              }
            >
              <PermCameraMicIcon sx={{ fontSize: "20px" }} />

              <p>Podcast</p>
            </div>
          ) : null}


{userRole?.role == 1 || userRole?.role == 2 ? (
            <div
              onClick={() => {
                history.push("/guest");
              }}
              className={
                isCollapse
                  ? `${
                      history.location.pathname == "/guest"
                        ? "sidebar-active sidebaropen"
                        : "sidebaropen"
                    }`
                  : `${
                      history.location.pathname == "/podcast"
                        ? "sidebar-active sidebarclose"
                        : "sidebarclose"
                    }`
              }
            >
              <EmojiPeopleIcon sx={{ fontSize: "20px" }} />

              <p>Guest</p>
            </div>
          ) : null}

          <div
            onClick={() => {
              history.push("/account");
            }}
            className={
              isCollapse
                ? `${
                    history.location.pathname == "/account"
                      ? "sidebar-active sidebaropen"
                      : "sidebaropen"
                  }`
                : `${
                    history.location.pathname == "/account"
                      ? "sidebar-active sidebarclose"
                      : "sidebarclose"
                  }`
            }
          >
            <PersonIcon sx={{ fontSize: "20px" }} />
            <p>Account</p>
          </div>
        </div>

        <div className="logout">
          <div
            onClick={() => {
              history.push("/");
              removeUserSession();
            }}
            className={isCollapse ? "sidebaropen" : "sidebarclose"}
          >
            <ExitToApp sx={{ fontSize: "20px" }} />

            <p>Logout</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
