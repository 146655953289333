import React, { useState } from "react";
import { Wrapper } from "./Signup.styled";
import { Input, Button, Form, notification } from "antd";
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";
import { serverAddress } from "../../../Constant";
import TopBar from "../../../components/topBar/TopBar";
import validator from "validator";
import { ErrorNotification, SuccessNotification } from "../../../components";
import { NavigateNext } from "@material-ui/icons";

export default function IndexPage() {
  const [userData, setUserData] = useState({});
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const SignUp = async () => {
    const qs = require("qs");
    await axios
      // .post(`${serverAddress}/register`, qs.stringify(userData))
      .post(`${serverAddress}/login/signup`, { ...userData, role: "user" })
      .then((res) => {
        setLoading(false);
        history.replace("/login");
        SuccessNotification("User created successfully");
        // history.replace("/");
      })
      .catch((err) => {
        console.log("error message==>", err.response.data);
        ErrorNotification(err.response.data.err)
        // notification({
        //   message: "Error",
        //   description: err.response.data.err,
        //   placement: "bottomLeft",
        //   type: "error",
        // });
        setLoading(false);
        // if (
        //   err.response.status === 422 &&
        //   validator.isEmail(userData.email) === false
        // ) {
        //   setErrorMsg("Invalid Email. Please Enter the valid Email");
        // }
        // if (
        //   err.response.status === 422 &&
        //   validator.isEmail(userData.email) === true &&
        //   userData.password.length >= 6
        // ) {
        //   setErrorMsg("This Email is Already in use");
        // }
        // if (userData.password.length < 6) {
        //   setErrorMsg("Password must be 6 character long.");
        // }
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    SignUp();
  };
  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.id]: e.target.value,
    });
    setErrorMsg(null);
  };
  const history = useHistory();
  const formComplete =
    !!userData.firstname &&
    !!userData.lastname &&
    !!userData.email &&
    !!userData.password;

  return (
    <Wrapper>
      <TopBar color />
      <div className="loginMain">
        <div className="bodyContent">
          <h2>Lets get started!</h2>
          <p className="signupButton">
            Already a member?{" "}
            <span
              style={{
                color: "#ff6a3c",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => history.push("/login")}
            >
              Login
            </span>
          </p>
          {errorMsg && (
            <>
              <small style={{ color: "red", fontSize: "15px" }}>
                {errorMsg}
              </small>
              <br />
            </>
          )}

          <Form style={{ display: "contents" }}>
            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  { required: true, message: "Please input your First Name!" },
                ]}
                type="text"
                onChange={handleChange}
                id="firstname"
                name="firstname"
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </div>
            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                ]}
                type="text"
                onChange={handleChange}
                id="lastname"
                name="lastname"
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </div>

            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Email!",
                  },
                ]}
                type="text"
                onChange={handleChange}
                id="email"
                name="email"
              >
                <Input placeholder="Enter Email address" />
              </Form.Item>
            </div>

            <div className="inputField">
              <Form.Item
                style={{ margin: "0px" }}
                initialValue=""
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                type="text"
                onChange={handleChange}
                id="password"
                name="password"
              >
                <Input.Password
                  autoComplete="off"
                  placeholder="Enter Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </div>

            {/* <div className="inputField">
                    <Input.Password placeholder="Confirm Password"
                        iconRender={
                            visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)
                        } />
                </div> */}

            <div className="bottomSection">
              <p style={{ marginTop: "20px" }}>
                By signing up, you agree to Episode Manager{" "}
                <span
                  style={{
                    color: "#ff6a3c",
                    cursor: "pointer",
                  }}
                >
                  Terms and conditions
                </span>
              </p>
              <Button
                style={{}}
                type="primary"
                onClick={handleSubmit}
                disabled={!formComplete}
                loading={loading}
              >
                Sign Up
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
}
