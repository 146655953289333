import React, { useState, useEffect } from "react";
import { ArrowUpward } from "@material-ui/icons";
import styled from "styled-components";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Voicemail, People, Storage, AttachMoney } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverAddress } from "../../Constant";
import { getToken, getUser } from "../../utils/Auth";
const myUser = getUser();

const Wrapper = styled.div`
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    background: white;

    .right {
      background-image: linear-gradient(to right, #ff4f19, #ff6a3c);
      padding: 12px 10px 3px 10px;
      border-radius: 10px;
      color: white;
      text-align: center;
    }

    .title {
      font-size: 14px;
      color: #1a2138;
    }
    .data {
      display: flex;
      align-items: center;
      color: #1a2138;

      .featuredMoney {
        font-size: 20px;
        font-weight: bold;
      }
      .featuredMoneyRate {
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-weight: bold;
        color: #4ddb00;
        font-size: 12px;
      }
    }
    .previous {
      font-size: 10px;
      color: gray;
    }
  }
`;

const AnaylticCard = ({ role }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let episodes = useSelector((state) => state.common.episodes);
  let SubUsers = useSelector((state) => state.common.SubUsers);
  let podcaster = useSelector((state) => state.common.podcaster);
  const [loader, setloader] = useState(true);
  const [featuredData, setFeaturedData] = React.useState([]);

  const getEpisodes = () => {
    axios
      .get(`${serverAddress}/episode/getAllEpisodes`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_EPISODES",
            payload: {
              episodes: res?.data,
            },
        });
        setloader(false);
      })
      .catch((err) => {
        console.log("error message==>", err);
      });
  };

  const GetUser = () => {
    axios
      .get("/teamMember/getAllTeamMember", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })

      .then((res) => {
        dispatch({
          type: "SET_SUB_USERS",
          payload: {
            SubUsers: res?.data?.data,
          },
        });
        setloader(false);
      })
      .catch((err) => {
        console.log("error message==>", err);
      });
  };

  const GetPodcaster = () => {
    axios
      .get("/podcaster/getAllPodcaster", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((res) => {
        console.log("res",res);
        dispatch({
          type: "PODCASTER_USERS",
          payload: {
            podcaster: res?.data,
          },
        });
        setloader(false);
      })
      .catch((err) => {
        console.log("error message==>", err);
      });
  };

  useEffect(() => {
    GetUser();
    getEpisodes();
    GetPodcaster();
    setloader(false);
  }, []);

  const adminRole = [
    {
      title: "Podcasters",
      value: podcaster?.length,
      moneyRate: "+0.0",
      icon: <GroupWorkIcon />,
      path: "/podcaster",
    },
    {
      title: "Team Members",
      value: SubUsers?.length,
      moneyRate: "+0.0",
      icon: <People />,
      path: "/teammembers",
    },
    {
      title: "Episodes",
      value: episodes?.length,
      moneyRate: "+0.0",
      icon: <Voicemail  />,
      path: "/episodes",
    },
    {
      title: "Reveneu",
      value: "$0.00",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
    {
      title: "Renewals in 30d, 60d ,90d and This Year",
      value: "0",
      moneyRate: "+0.0",
      icon: <FiberNewIcon />,
    },
    {
      title: "Cancelled",
      value: "0",
      moneyRate: "+0.0",
      icon: <ClearIcon />,
    },
    {
      title: "Episodes Completed",
      value: "0",
      moneyRate: "+0.0",
      icon: <CheckCircleOutlineIcon />,
    },
    {
      title: "Episodes in Progress",
      value: "0",
      moneyRate: "+0.0",
      icon: <DonutLargeIcon />,
    },
  ];

  const podcasterRole = [
    {
      title: "Team Members",
      value: SubUsers?.length,
      moneyRate: "+0.0",
      icon: <People />,
      path: "/teammembers",
    },
    {
      title: "Episodes",
      value: episodes?.length,
      moneyRate: "+0.0",
      icon: <Voicemail  />,
      path: "/episodes",
    },
    {
      title: "Episodes Completed",
      value: "0",
      moneyRate: "+0.0",
      icon: <Storage />,
    },
    {
      title: "Episodes in Progress",
      value: "0",
      moneyRate: "+0.0",
      icon: <Storage />,
    },
    {
      title: "Billing Or Account",
      value: "$0.00",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
    {
      title: "Account Profile",
      value: "0",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
    {
      title: "Guest",
      value: "0",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
    {
      title: "Upcoming Interview",
      value: "0",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
  ];

  const teamRole = [
    {
      title: "Episodes",
      value: episodes?.length,
      moneyRate: "+0.0",
      icon: <Voicemail  />,
      path: "/episodes",
    },
    {
      title: "Episodes Completed",
      value: "0",
      moneyRate: "+0.0",
      icon: <Storage />,
    },
    {
      title: "Episodes in Progress",
      value: "0",
      moneyRate: "+0.0",
      icon: <Storage />,
    },
    {
      title: "Upcoming Interview",
      value: "0",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
    {
      title: "Guest",
      value: "0",
      moneyRate: "+0.0",
      icon: <AttachMoney />,
    },
  ];

  React.useEffect(() => {
    if (role == 1) {
      setFeaturedData(adminRole);
      return;
    }
    if (role == 2) {
      setFeaturedData(podcasterRole);
      return;
    }
    if (role == 3) {
      setFeaturedData(teamRole);
      return;
    }
  }, [role]);

  return (
    <Wrapper>
      <Row gutter={[15, 15]}>
        {featuredData &&
          featuredData?.map((item, index) => (
            <Col xs={24} sm={24} md={6} lg={6} xl={6} key={index}>
              <div
                key={index}
                className="card-container"
                onClick={() => history.push(item.path)}
              >
                <div className="left">
                  <div className="title">{item.title}</div>
                  <div className="data">
                    <span className="featuredMoney">{item.value}</span>
                    {/* <span className="featuredMoneyRate">{item.moneyRate}</span> */}
                  </div>
                  {/* <div className="previous">Compared to Previous month</div> */}
                </div>
                <div className="right">{item.icon}</div>
              </div>
            </Col>
          ))}
      </Row>
    </Wrapper>
  );
};

export default AnaylticCard;
