import styled from "styled-components";

export const Wrapper = styled.div`
  position: sticky;
  .topBar {
    display: flex;
    justify-content: space-between;
  }

  .leftSide {
    display: flex;
  }

  .title {
    padding-left: 30px;
    font-weight: bold;
    color: #ff6a3c;
    .titleFixed {
      color: #afafaf;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .ant-btn {
    background: #1a2138;
    color: #9799aa;
    margin-right: 5px;
    border-radius: 5px;
  }
  .ant-btn:hover {
    background: #1a2138;
    color: #fff;
    margin-right: 5px;
    border-radius: 5px;
  }
  .rightSide {
    display: flex;
    line-height: 0px;
    align-items: center;
    font-weight: bold;
    padding-right: 30px;
    .notification {
      cursor: pointer;
    }
    .admin-picture {
      width: 35px;
      border-radius: 50%;
      outline: 3px solid #ff6a3c;
      margin: 0px 15px 0px 25px;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  .hamBurgerHide {
    display: none;
  }
  .hamBurgerShow {
    padding: 0px 20px 0px 20px;
    background: #ff6a3c;
    cursor: pointer;
  }

  .sideDrawerIcon {
    padding: 0px 20px 0px 20px;
    cursor: pointer;
    display: none;
  }

  .settingDetails {
    color: white;
  }

  @media (max-width: 1000px) {
    .hamBurgerShow {
      display: none;
    }
    .headingShow {
      padding-left: 10px !important;
    }
  }
  @media (max-width: 1000px) {
    .sideDrawerIcon {
      display: inline;
    }
  }

  @media (max-width: 1420px) {
    .headingShow {
      margin-right: 10px;
    }
  }

  .ant-btn {
    background: #1a2138;
    color: #9799aa;
    margin-right: 5px;
    border-radius: 5px;
  }
`;
